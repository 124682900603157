import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  FormControl,
  InputLabel,
  CircularProgress,
  Paper,
  LinearProgress,
  makeStyles,
  Select,
  MenuItem
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ModalDetailCharge from './components/ModalDetailCharge';
import ModalSearch from './components/ModalSearch';
import { Pagination } from "react-bootstrap";
import { normalizeCnpj } from "./utils/normalize";
import { format } from 'date-fns';
import { toast } from 'react-toastify';

export default function ChargeIndex() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState({});
  const [search, setSearch] = useState(false);

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: 20,
    },
  }));
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  async function loadCharge() {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/charge-list/operation?page=${page}&perPage=${rowsPerPage}`);
      setRows(response.data.operations);
      setTotal(response.data.total);
    }catch(err) {
      toast.error('Ocorreu um erro na listagem');
    }
    setLoading(false);
    // setRows(response.data);
  }

  async function searchRequest({ by, value }) {
    setLoading(true);
    setFilter(true);
    try {
      if(by === 0) {
        const response = await axios.get(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/charge-list/operations/filter?flag=CNPJ&value=${value.replace(/[^\d]/g, '')}`);
        setRows(response.data);
      }else {
        const response = await axios.get(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/charge-list/operations/filter?flag=BORDERO&value=${Number(value)}`);
        setRows(response.data);
      }
    } catch(err) {
      if(err.response && err.response.status === 404) {
        toast.info('Nenhum borderô encontrada');
      }else{
        toast.error('Ocorreu um erro ao buscar borderôs');
      }
    }
    setLoading(false);
  }

  function progressBar(item) {
    let percRead = 0;
    let percInvalid = 0;
    let percNotRead = 0;
    let percNoEmail = 0;
    let percSending = 0;
    item.sacados.forEach((i) => {
      if(i.events.length > 0) {
        const item = i.events[i.events.length - 1];
      if(item.event === 'open' || item.event === 'click') {
        percRead += 1;
      }else if(item.event === 'dropped' || item.event === 'deferred' || item.event === 'bounce') {
        percInvalid += 1;
      }else if(item.event === 'delivered' || item.event === 'processed') {
        percNotRead += 1;
      }
      }else{
        if(i.email === "") {
          percNoEmail += 1;
        }else{
          percSending += 1;
        }
      }
    });
    return (
    <div
        className="text-center"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="progress" style={{ width: "100%", maxWidth: "300px" }}>
        <div
            className={`progress-bar bg-secondary text-dark`}
            style={{ width: `${Math.round((percSending/item.sacados.length)*100)}%` }}
          >
            {Math.round((percSending/item.sacados.length)*100) > 0 && `${Math.round((percSending/item.sacados.length)*100)}%`}
          </div>
          <div
            className={`progress-bar kt-bg-brand`}
            style={{ width: `${Math.round((percNotRead/item.sacados.length)*100)}%` }}
          >
            {Math.round((percNotRead/item.sacados.length)*100) > 0 && `${Math.round((percNotRead/item.sacados.length)*100)}%`}
          </div>
          <div
            className={`progress-bar kt-bg-success`}
            style={{ width: `${Math.round((percRead/item.sacados.length)*100)}%` }}
          >
            {Math.round((percRead/item.sacados.length)*100) > 0 && `${Math.round((percRead/item.sacados.length)*100)}%`}
          </div>
          <div
            className={`progress-bar kt-bg-warning`}
            style={{ width: `${Math.round((percNoEmail/item.sacados.length)*100)}%` }}
          >
            {Math.round((percNoEmail/item.sacados.length)*100) > 0 && `${Math.round((percNoEmail/item.sacados.length)*100)}%`}
          </div>
          <div
            className={`progress-bar kt-bg-danger`}
            style={{ width: `${Math.round((percInvalid/item.sacados.length)*100)}%` }}
          >
            {Math.round((percInvalid/item.sacados.length)*100) > 0 && `${Math.round((percInvalid/item.sacados.length)*100)}%`}
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    loadCharge();
  }, [page, rowsPerPage]); // eslint-disable-line
  
  const headRows = [
    { id: 'operation', align: "left", disablePadding: true, label: 'Borderô' },
    { id: 'cedent', align: "left", disablePadding: false, label: 'Cedente' },
    { id: 'sacados', align: "left", disablePadding: false, label: 'Sacados' },
    { id: 'progress', align: "center", disablePadding: false, label: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: '100%'
        }}
      >
        Progresso
        <div
          className="kt-widget__stats"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginTop: "10px",
            width: '100%',
            fontSize: '10px'
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 6px",
            }}
          >
            <div
              className="bg-secondary"
              style={{ width: "10px", height: "10px" }}
            ></div>
            Enviando
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 6px",
            }}
          >
            <div
              className="kt-bg-brand"
              style={{ width: "10px", height: "10px" }}
            ></div>
            Enviado
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 6px",
            }}
          >
            <div
              className="kt-bg-success"
              style={{ width: "10px", height: "10px" }}
            ></div>
            Lido
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 6px",
            }}
          >
            <div
              className="kt-bg-warning"
              style={{ width: "10px", height: "10px" }}
            ></div>
            Não possui email
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 6px",
            }}
          >
            <div
              className="kt-bg-danger"
              style={{ width: "10px", height: "10px" }}
            ></div>
            Inválido
          </div>
        </div>
      </div>
    ) },
    { id: 'date', align: "right", disablePadding: false, label: 'Data do Envio' },
    { id: 'actions', align: "right", disablePadding: false, label: 'Ações' },
  ];
  
  function EnhancedTableHead(props) {
    const { order, orderBy, /*onRequestSort*/ } = props;
    /* const createSortHandler = property => event => {
      onRequestSort(event, property);
    }; */
  
    return (
      <TableHead>
        <TableRow>
          {headRows.map(row => (
            <TableCell
              key={row.id}
              align={row.align}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={() => {}}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleNextPage() {
    const nextPage = page + 1
    setPage(nextPage);
  }

  function handlePreviousPage() {
    const previousPage = page - 1
    setPage(previousPage);
  }

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Operação: Notificação / Cobrança
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/agents`}>
                Operação: Notificação / Cobrança
              </Link>
            </div>
          </div>
          <div className="kt-subheader__toolbar">
            <div className="d-flex flex-row kt-subheader__wrapper">
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: '10px' }}>
              <button type="button" className="btn btn-light" onClick={() => setSearch(true)}>
                  Buscar
                  <i className="fa fa-search" style={{ marginLeft: "10px" }}></i>
                </button>
                {filter && (
                <button type="button" className="btn btn-warning" onClick={() => {
                  setFilter(false);
                  loadCharge();
                }}>
                  Esvaziar Filtro
                </button>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalSearch open={search} setOpen={(e) => setSearch(e)} getConsult={({ by, value }) => searchRequest({ by, value })}/>
      <ModalDetailCharge open={open} setOpen={e => setOpen(e)} item={view} onResend={loadCharge}/>
      <Paper className={classes.paper}>
        <div>
        {loading && <LinearProgress color="primary" />}
          <Table
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row._id}
                    >
                      <TableCell align="left">{row.bordero}</TableCell>
                      <TableCell align="left">{row.avalista} <br></br>
                      CNPJ: {normalizeCnpj(row.avalista_id)}</TableCell>
                      <TableCell align="left">{row.sacados.length}</TableCell>
                      <TableCell align="center">{progressBar(row)}</TableCell>
                      <TableCell align="right">
                        {format(new Date(row.createdAt), "dd/MM/yyyy 'às' HH:mm")}
                      </TableCell>
                      <TableCell align="right">
                        <button
                          type="button"
                          className="btn btn-sm pl-2 pr-2"
                          onClick={() => {
                            console.log('row',row)
                            setView(row);
                            setOpen(true);
                          }}>
                          <VisibilityIcon />
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {rows && rows.length > 0 && (
          <div className="align-items-end d-flex justify-content-between mt-5">
            <span>{total} Total</span>
            <div className="d-flex align-items-center">
              {loading && (
                <CircularProgress size={15} className="mr-2" />
              )}
            <Pagination>
              {page > 0 && (
                <>
                  <Pagination.First onClick={() => setPage(0)} disabled={page === 0 || loading} />
                  <Pagination.Prev onClick={() => handlePreviousPage()} disabled={loading}/>
                </>
              )}
              <Pagination.Item disabled>{page + 1}</Pagination.Item>
              {page < (Math.ceil(total/rowsPerPage) - 1) && (
                <>
                <Pagination.Next onClick={() => handleNextPage()} disabled={loading}/>
                <Pagination.Last onClick={() => setPage(Math.ceil(total/rowsPerPage) - 1)} disabled={loading}/>
                </>
              )}
            </Pagination>
            </div>
            <FormControl
                style={{ width: "100%", maxWidth: "120px", marginRight: "10px" }}
                disabled={loading}
              >
                <InputLabel htmlFor="results_per_page">Result. p/Página</InputLabel>
                <Select
                  id="results_per_page"
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(e.target.value)}
                  disabled={loading}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
          </div>
        )}
      </Paper>
    </div>
  );
}
