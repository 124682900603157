import React, { memo, useState } from "react";

import { format } from "date-fns";
import { toast } from "react-toastify";

import printChargeEmail from "../utils/printChargeEmail";
import printChargeReportEmail from "../utils/printChargeReportEmail";
import { formatEvent } from "../utils/normalize";

function ItemDetail({ payer, index, onResend }) {
  const [expanded, setExpanded] = useState(false);

  async function handlePrintDetail() {
    try {
      printChargeEmail({
        createdAt: format(
          new Date(payer.createdAt),
          "dd/MM/yyyy 'às' HH:mm"
        ),
        sacado: {
          ...payer
        },
        events: payer.titles
      });
    } catch (err) {
      toast.error("Ocorreu um erro, tente novamente mais tarde");
    }
  }

  async function handlePrintReport(situation) {
    try {
      let weekDay = format(new Date(payer.createdAt), "i");

      if (weekDay === "1") {
        weekDay = "segunda-feira";
      } else if (weekDay === "2") {
        weekDay = "terça-feira";
      } else if (weekDay === "3") {
        weekDay = "quarta-feira";
      } else if (weekDay === "4") {
        weekDay = "quinta-feira";
      } else if (weekDay === "5") {
        weekDay = "sexta-feira";
      } else if (weekDay === "6") {
        weekDay = "sábado";
      } else if (weekDay === "7") {
        weekDay = "domingo";
      }

      const monthNumber = format(new Date(payer.createdAt), "M");

      let month = "";
      if (monthNumber === "1") {
        month = "janeiro";
      } else if (monthNumber === "2") {
        month = "fevereiro";
      } else if (monthNumber === "3") {
        month = "março";
      } else if (monthNumber === "4") {
        month = "abril";
      } else if (monthNumber === "5") {
        month = "maio";
      } else if (monthNumber === "6") {
        month = "junho";
      } else if (monthNumber === "7") {
        month = "julho";
      } else if (monthNumber === "8") {
        month = "agosto";
      } else if (monthNumber === "9") {
        month = "setembro";
      } else if (monthNumber === "10") {
        month = "outubro";
      } else if (monthNumber === "11") {
        month = "novembro";
      } else if (monthNumber === "12") {
        month = "dezembro";
      }

      const createdAtComplete = `${weekDay}, ${format(
        new Date(payer.createdAt),
        "dd"
      )} de ${month} de ${format(
        new Date(payer.createdAt),
        "yyyy  HH:mm"
      )}`;

      printChargeReportEmail({
        createdAtComplete: createdAtComplete,
        createdAt: format(
          new Date(payer.createdAt),
          "dd/MM/yyyy 'às' HH:mm"
        ),
        sacado: payer,
        events: payer.titles,
        situation: situation
      });
    } catch (err) {
      toast.error("Ocorreu um erro, tente novamente mais tarde");
    }
  }

  return (
    <div
      key={index}
      style={{
        padding: "15px",
        border: "1px solid #eee",
        margin: "20px 0px",
        background: "#f9f9f9",
        position: "relative"
      }}
    >
      <div className="d-flex justify-content-start align-items-start">
        <div style={{ textAlign: "left" }}>
          <h4>{payer.name}</h4>
          <p className="mb-1">
            <i className="fas fa-id-card mr-2"></i>
            {payer.document}
          </p>
          <p className="mb-1">
            <i className="fas fa-envelope mr-2"></i>
            {payer.email || "-"}
          </p>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <button
            className="btn btn-sm btn-upper btn-info pl-1 pr-1 pt-0 pb-0 mr-2"
            onClick={() => handlePrintDetail()}
          >
            Imprimir PDF
          </button>
          {(payer.status === 'delivered' || payer.status === 'opened' || payer.status === 'clicked') &&
            <button
              className="btn btn-sm btn-upper btn-info pl-1 pr-1 pt-0 pb-0 mr-4"
              onClick={() => handlePrintReport(formatEvent(payer.status, true))}
            >
              Imprimir relatório
            </button>
          }
          <button
          className="btn btn-sm pl-0 pr-0 ml-3"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (
            <>
              Ver menos <i className="fas fa-angle-up ml-2"></i>
            </>
          ) : (
            <>
              Ver mais <i className="fas fa-angle-down ml-2"></i>
            </>
          )}
        </button>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          {payer.sends && payer.sends.map(send => (
            <div className="d-flex align-items-center" key={send._id}>
              <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                {format(new Date(send.date_processing), "dd/MM/yyyy 'às' HH:mm")}
              </span>
              {formatEvent(send.status)}
            </div>
          ))}
        </div>
      <ul
        style={{
          listStyle: "none",
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          marginTop: "20px",
          padding: "0",
          justifyContent: "flex-end"
        }}
      >
        {payer.titles && payer.titles.map((e, i) => {
          return (
            <li
              className="mr-3"
              style={{ textAlign: "center", fontSize: "11px" }}
              key={i}
            >
              <a
                href={`${
                  process.env.REACT_APP_API_BFC_DIGITAL_URL
                }/send-mail-mailersend/boleto/titulo_${e.title_id}.pdf`}
                className="d-flex flex-column"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="far fa-file-alt"
                  style={{ fontSize: "24px", marginBottom: "5px" }}
                ></i>
                {e.title_id}.pdf
              </a>
            </li>
          );
        })}
      </ul>
      </div>
      {expanded && (
        <>
          <hr />
          <h6>Envios</h6>
          {payer.sends && payer.sends.map(send => (
            <div key={send._id}>
              <div className="d-flex justify-content-between">
                <p className="m-0">Hash: {send.sg_message_id}</p>
              </div>
              <div className="d-flex justify-content-between">
              <p className="m-0">
                  Data de Processamento:{" "}
                  {format(new Date(send.date_processing), "dd/MM/yyyy 'às' HH:mm")}
                </p>
                {formatEvent(send.status)}
              </div>
              <table className="table table-bordered mt-2">
                <thead>
                  <tr className="bg-secondary text-dark">
                    <th>E-mail</th>
                    <th>Evento</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {send.events && send.events.map(e => (
                    <tr key={e.id}>
                      <td>{e.email}</td>
                      <td>{formatEvent(e.event)}</td>
                      <td>{e.timestamp ? format(new Date(e.timestamp), "dd/MM/yyyy 'às' HH:mm") : ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default memo(ItemDetail);
