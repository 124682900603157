import React, { memo, useEffect, useState } from "react";

import { Modal, Backdrop, makeStyles, CircularProgress } from "@material-ui/core";

import axios from 'axios';
import { toast } from 'react-toastify';

import { normalizeCnpj } from "../utils/normalize";
import ItemDetail from "./ItemDetail";

function ModalDetailProtestPayer({ open, setOpen, item }) {
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState(null);
  const [originalPayers] = useState(null);

  const useStyles = makeStyles((theme) => ({
    modalsm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fadesm: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      borderRadius: "5px",
      width: "100%",
      maxWidth: "1000px",
      height: "80%",
      padding: "30px",
      overflow: "auto",
    },
  }));

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/bordero/sacados/${item}`
        );

        if (response.status === 204) {
          const { data } = await axios.get(`https://gateway-ovh.grupobfc.com.br/payerProtest/operations/${item}`);
          setDetail(data);
        } else {
          const { titles } = response.data;

          let payers = [];

          titles.forEach((title) => {
            if (
              !payers.find(
                (relato) => relato.documentNumber === title.document_sacado
              )
            ) {
              payers.push({
                name: title.sacado,
                documentNumber: title.document_sacado,
              });
            }
          });

          const { data } = await axios.get(`https://gateway-ovh.grupobfc.com.br/payerProtest/operations/${item}`);

          const { payers: protestPayers } = data;

          const pendingPayers = payers.filter(payer => !protestPayers.find(p => payer.documentNumber === p.documentNumber || payer.documentNumber === `000${p.documentNumber}`))

          setDetail({ ...data, payers: [...data.payers, ...pendingPayers] });
        }
      } catch (err) {
        if (err.response && err.response.status === 500) {
          toast.error('Ocorreu um erro em nossos servidores, contate a equipe técnica');
        } else if (err.response && err.response.status === 404) {
          toast.warn('Relato não encontrado');
        } else {
          toast.error('Ocorreu um erro em nossa aplicação, contate a equipe técnica');
        }
      }
      setLoading(false);
    }
    if (item) {
      loadData();
    }
  }, [item]);

  function resetForm() {
    setOpen(false);
    setDetail(null);
  }

  const classes = useStyles();

  return (
    <Modal
      className={classes.modalsm}
      open={open}
      onClose={resetForm}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <div className={classes.fadesm}>
        <div className="d-flex justify-content-between align-items-start">
          <h2 className="mb-4">Detalhamento do Relato</h2>
          <button type="button" onClick={resetForm} className="btn">
            <i className="fas fa-times"></i>
          </button>
        </div>
        {loading && (
          <>
            <CircularProgress /> Buscando
          </>
        )}
        {open && item && detail && !loading && (
          <>
            <h2
              style={{
                border: "1px solid rgb(100, 108, 154)",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Borderô: {detail.borderoId}
            </h2>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <table className="table table-bordered">
                <thead>
                  <tr className="bg-secondary text-dark">
                    <td>Cedente</td>
                    <td>N° de Sacados</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {detail.assignorName} - {normalizeCnpj(detail.assignorDocumentNumber)}
                    </td>
                    <td>{originalPayers?.length}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {detail.payers && detail.payers.length > 0 ? (
              <div style={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
                {detail.payers.map((e, index) => {
                  return (
                    <ItemDetail e={e} index={index} key={index} detail={detail}/>
                  )
                }
                )}
              </div>
            ) : (
                <h4>Nenhum sacado encontrado...</h4>
              )}
          </>
        )}
      </div>
    </Modal>
  );
}

export default memo(ModalDetailProtestPayer);
