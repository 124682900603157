import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { Paper, Button, CircularProgress, LinearProgress, FormControl, InputLabel, Select, MenuItem, TextField } from "@material-ui/core";
import { Mail, Visibility, Search } from "@material-ui/icons";

import statusRegisterCompleted from "./utils/statusRegisterCompleted";

import axios from 'axios';
import { toast } from 'react-toastify';
import { Table, Pagination } from "react-bootstrap";

import { format } from 'date-fns';

import MailSender from "./components/MailSender";
import Detail from "./components/Detail";
import { normalizeCnpj, normalizeDate } from "./normalize"

function MailPage() {
  const [sendMail, setSendMail] = useState(false);
  const [openDetail, setOpenDetail] = useState({
    active: false,
    item: {},
  });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [user, setUser] = useState({
    email: '',
    fullname: '',
    id: '',
    innactivated: '',
    pic: '',
    profile: '',
    roles: []
  });
  const [filters, setFilters] = useState({
    user_id: undefined,
    email: '',
    initial_date: '',
    final_date: '',
    cnpj: '',
    status: '',
  });
  const [users, setUsers] = useState([]);

  const [avulses, setAvulses] = useState([]);

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {

        const { data: userData } = await axios.get('auth');

        setUser(userData);

        const { data: usersData } = await axios.get(`/roles/users/8`);

        setUsers(usersData)

        const { data } = await axios.get(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/charge/avulses`, {
          params: {
            page,
            perPage,
            origin: 'prospection',
            user_id: userData.roles.filter(r => r.id === 1).length > 0 ? undefined : userData.id
          }
        });

        setAvulses(data.map(d => {
          return {
            ...d,
            messageText: new DOMParser().parseFromString(`${d.message}`, 'text/html').body.innerText.slice(0, 25)
          }
        }));

      } catch (err) {
        toast.error(err.message);
      }
      setLoading(false);
    }
    loadData();
  }, [page, perPage]);

  function handlePreviousPage() {
    setPage(page - 1);
  }

  function handleNextPage() {
    setPage(page + 1);
  }

  async function loadAvulses() {
    const { user_id, email, initial_date: filter_initial_date, final_date: filter_final_date, cnpj, status } = filters

    let initial_date;
    let final_date;

    if (filter_initial_date && filter_final_date) {
      const [day1, month1, year1] = filter_initial_date.split('/');
      const [day2, month2, year2] = filter_final_date.split('/');

      initial_date = new Date(`${year1}-${month1}-${day1}`)
      final_date = new Date(`${year2}-${month2}-${day2}`)
    }

    const { data } = await axios.get(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/charge-list/avulses`, {
      params: {
        page,
        perPage,
        origin: 'prospection',
        user_id,
        email: email !== '' ? email : undefined,
        cnpj: cnpj !== '' ? cnpj.replace(/\D/g, '') : undefined,
        status: status !== '' ? status : undefined,
        initial_date,
        final_date,
      }
    });

      setAvulses(data.map(d => {
        return {
          ...d,
          messageText: new DOMParser().parseFromString(`${d.message}`, 'text/html').body.innerText.slice(0, 25)
        }
      }));    
  }


  return (
    <>
      {sendMail && (
        <MailSender open={sendMail} setOpen={(e) => setSendMail(e)} onSend={() => setPage(0)} user={user} />
      )}
      <Detail
        open={openDetail.active}
        setOpen={() => setOpenDetail({
          active: false,
          item: {},
        })}
        detail={openDetail.item}
      />
      <div style={{ width: "100%" }}>
        <div className="kt-subheader kt-grid__item">
          <div className="kt-container ">
            <div className="kt-subheader__main">
              <h3 className="kt-subheader__title">Envio de E-mail</h3>
              <div className="kt-subheader__breadcrumbs">
                <Link to="/" className="kt-subheader__breadcrumbs-home">
                  <i className="flaticon2-shelter" />
                </Link>
                <span className="kt-subheader__breadcrumbs-separator" />
                <Link className="kt-subheader__breadcrumbs-link" to={`/mail`}>
                  Envio de E-mail
                </Link>
              </div>
            </div>
            <div className="kt-subheader__toolbar">
              <div className="kt-subheader__wrapper d-flex align-items-right">
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  className="ml-3"
                  startIcon={<Mail />}
                  onClick={() => setSendMail(true)}
                >
                  Enviar E-mail
                </Button>
              </div>
            </div>
          </div>
        </div>

        <Paper
          style={{ padding: "40px", width: "100%" }}
          className="d-flex flex-column justify-content-center align-items-center"
        >

          {
            user && user.roles.filter(r => r.id === 1).length > 0 && (
              <>
                <Button color="primary" startIcon={<Search />} className="d-flex mb-2 ml-auto" variant="contained" onClick={() => loadAvulses()}>
                  {loading && (<CircularProgress size="16px" className="mr-2" />)}
                  Filtrar
                </Button>
                <div className="row mt-2" style={{ width: "100%" }}>
                  <FormControl
                    variant="outlined"
                    className="col mr-4"
                    style={{ width: "100%" }}
                    disabled={loading}
                    size="small"
                  >
                    <InputLabel htmlFor="users_filter">Filtrar por usuário</InputLabel>
                    <Select
                      id="users_filter"
                      onChange={(event) => {
                        if (event.target.value === "Todos") {
                          setFilters({ ...filters, user_id: undefined })
                        } else {
                          setFilters({ ...filters, user_id: event.target.value })
                        }
                      }}
                      label="Filtrar por usuário"
                      style={{ width: "100%" }}
                      size="small"
                    >
                      <MenuItem value="Todos">Todos</MenuItem>
                      {users.length > 0 && users.map(u => (
                        <MenuItem key={u.id} value={u.id}>{u.fullname}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    variant="outlined"
                    className="col mr-4"
                    style={{ width: "100%" }}
                    disabled={loading}
                    size="small"
                  >
                    <InputLabel htmlFor="status_filter">Filtrar por status</InputLabel>
                    <Select
                      id="status_filter"
                      onChange={(event) => {
                        setFilters({ ...filters, status: event.target.value })
                      }}
                      label="Filtrar por usuário"
                      style={{ width: "100%" }}
                      size="small"
                    >
                      <MenuItem value="Enviando">Enviando</MenuItem>
                      <MenuItem value="Enviado">Enviado</MenuItem>
                      <MenuItem value="Lido">Lido</MenuItem>
                      <MenuItem value="Inválido">Inválido</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl
                    variant="outlined"
                    className="col mr-4"
                    style={{ width: "100%" }}
                    disabled={loading}
                  >
                    <TextField
                      variant="outlined"
                      style={{ width: "100%" }}
                      label="Cnpj"
                      disabled={loading}
                      value={filters.cnpj}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          cnpj: normalizeCnpj(e.target.value),
                        })
                      }
                      size="small"
                    />
                  </FormControl>

                  <FormControl
                    variant="outlined"
                    className="col mr-4"
                    style={{ width: "100%" }}
                    disabled={loading}
                  >
                    <TextField
                      variant="outlined"
                      style={{ width: "100%" }}
                      label="Email"
                      disabled={loading}
                      value={filters.email}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          email: e.target.value
                        })
                      }
                      size="small"
                    />
                  </FormControl>
                  <div className="d-flex align-items-center"> 
                  <span>De: </span>
                  <FormControl
                    variant="outlined"
                    className="col ml-2 mr-2"
                    style={{ width: "100%" }}
                    disabled={loading}
                  >
                    <TextField
                      variant="outlined"
                      style={{ width: "100%" }}
                      label="Data inicial"
                      disabled={loading}
                      value={filters.initial_date}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          initial_date: normalizeDate(e.target.value),
                        })
                      }
                      size="small"
                    />
                  </FormControl>
                  </div>
                  <div className="d-flex align-items-center"> 
                  <span>Até: </span>
                  <FormControl
                    variant="outlined"
                    className="col ml-2"
                    style={{ width: "100%" }}
                    disabled={loading}
                  >
                    <TextField
                      variant="outlined"
                      style={{ width: "100%" }}
                      label="Data final"
                      disabled={loading}
                      value={filters.final_date}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          final_date: normalizeDate(e.target.value),
                        })
                      }
                      size="small"
                    />
                  </FormControl>
                  </div>
                </div>
                <hr style={{ width: '100%' }} />
              </>
            )
          }

          {loading && (
            <LinearProgress />
          )}
          <Table hover>
            <thead>
              <tr>
                <th>Assunto</th>
                <th>Destinatário</th>
                <th>CNPJ</th>
                <th>Mensagem</th>
                <th className="text-center">Status</th>
                <th className="text-center">Data de envio</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {avulses.map(avulse => (
                <tr key={avulse._id}>
                  <td>{avulse.subject}</td>
                  <td>{avulse.email}</td>
                  <td>{normalizeCnpj(avulse.cnpj) || '-'}</td>
                  <td>{avulse.messageText}...</td>
                  <td className="text-center">{avulse.events.length > 0 ? statusRegisterCompleted(avulse.events[avulse.events.length - 1].event) : 'Enviando...'}</td>
                  <td className="text-center">{format(new Date(avulse.createdAt), 'dd/MM/yyyy - HH:mm')}</td>
                  <td className="text-right">
                    <button
                      type="button"
                      style={{ background: "none", border: "none" }}
                      onClick={() => setOpenDetail({
                        active: true,
                        item: avulse,
                      })}
                      disabled={loading}
                    >
                      <Visibility />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div style={{ width: '100%' }} className="align-items-end d-flex justify-content-between mt-5">
            <span></span>
            <div className="d-flex align-items-center">
              {loading && (
                <CircularProgress size={15} className="mr-2" />
              )}
              <Pagination>
                {page > 0 && (
                  <>
                    <Pagination.First onClick={() => setPage(0)} disabled={page === 0 || loading} />
                    <Pagination.Prev onClick={() => handlePreviousPage()} disabled={loading} />
                  </>
                )}
                <Pagination.Item disabled>{page + 1}</Pagination.Item>
                <Pagination.Next onClick={() => handleNextPage()} disabled={loading} />
              </Pagination>
            </div>
            <FormControl
              style={{ width: "100%", maxWidth: "120px", marginRight: "10px" }}
              disabled={loading}
            >
              <InputLabel htmlFor="results_per_page">Result. p/Página</InputLabel>
              <Select
                id="results_per_page"
                value={perPage}
                onChange={(e) => setPerPage(e.target.value)}
                disabled={loading}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={250}>250</MenuItem>
                <MenuItem value={1000}>1000</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Paper>
      </div>
    </>
  );
}

export default MailPage;
