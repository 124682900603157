import { FormControl, InputLabel, LinearProgress, makeStyles, MenuItem, Select, TablePagination, } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import RegisterSearch from "./RegisterSearch";
import { normalizeCnpj, normalizePhone } from "./utils/normalize";
import progressRegister from "./utils/progressRegister";
import statusRegisterCompleted from "./utils/statusRegisterCompleted";

export default function RegisterIndex() {
  const [user, setUser] = useState({ roles: [] });
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [filter, setFilter] = useState(false);
  const [total, setTotal] = useState(0)
  const [gerentes, setGerentes] = useState([]);
  const history = useHistory();

  const headerElement = useRef(null)

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: "auto",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "500px",
    },
  }));
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [searchStatus, setSearchStatus] = useState(null);

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get('auth');
      setUser(data);
    }
    loadUser();
  }, []);

  async function loadRegisters() {
    setLoading(true);
    try {
      const response = await axios.get(`/registers`, {
        params: {
          page: page + 1,
          per_page: rowsPerPage,
          managers: user.managers.map(e => e.gerente_id),
          gerente_id: user.gerente_id
        }
      });
      setRows(response.data.data);
      setTotal(response.data.total)
    } catch (err) {
      history.goBack();
    }
    setLoading(false);
  }

  useEffect(() => {
    async function loadGerente() {
      try {
        const { data } = await axios.get("facta/gerente");
        setGerentes(data);
      } catch (error) {}
    }
    loadGerente();
  }, []);

  useEffect(() => {
    if(user.roles.length > 0) {
      const filter = localStorage.getItem("filter@registers");
      if (filter && filter !== "Todos") {
        setSearchStatus(filter);
        submitSearchStatus();
        setFilter(true);
      } else {
        loadRegisters();
      }
    }
  }, [user, page, rowsPerPage]); // eslint-disable-line

  async function submitSearchCnpj(value, by) {
    setLoading(true);
    const { data } = await axios.get(
      `/registers`, {
        params: {
          page: 1,
          per_page: rowsPerPage,
          managers: user.managers.map(e => e.gerente_id),
          gerente_id: user.gerente_id,
          cnpj: by !== 2 ? value.replace(/\D/g, "") : undefined,
          user_id: by === 2 ? value : undefined,
        }
      }
    );
    setLoading(false);
    setPage(0);
    setRows(data.data);
    setFilter(true);
  }

  function formatName(name) {
    if (name.length < 20) {
      return name;
    } else if (name.length > 50) {
      return (
        <>
          {name.slice(0, 20)}
          <br />
          {name.slice(20, 40)}...
        </>
      );
    }
    return (
      <>
        {name.slice(0, Math.floor(name.length / 2))}
        <br />
        {name.slice(Math.floor(name.length / 2), name.length)}
      </>
    );
  }

  async function submitSearchStatus(value) {
    if (value && value !== "Todos") {
      setLoading(true);
      const { data } = await axios.get(`/registers`, {
        params: {
          page: page + 1,
          per_page: rowsPerPage,
          managers: user.managers.map(e => e.gerente_id),
          gerente_id: user.gerente_id,
          completed: value
        }
      });
      setLoading(false);
      setRows(data.data);
      setPage(0);
      setFilter(true);
    } else {
      loadRegisters();
      setFilter(false);
    }
  }

  const headRows = [
    {
      id: "razao_social",
      numeric: false,
      align: "left",
      disablePadding: true,
      label: "Empresa",
    },
    {
      id: "cnpj",
      numeric: false,
      align: "left",
      disablePadding: true,
      label: "CNPJ",
    },
    {
      id: "contact",
      numeric: false,
      align: "left",
      disablePadding: false,
      label: "Contato",
    },
    {
      id: "manager/agent",
      numeric: false,
      align: "left",
      disablePadding: false,
      label: "Gerente/Agente",
    },
    {
      id: "perc",
      numeric: false,
      align: "center",
      disablePadding: false,
      label: "Progresso",
    },
    {
      id: "status",
      numeric: false,
      align: "center",
      disablePadding: false,
      label: "Status",
    },
    {
      id: "created_at",
      numeric: false,
      align: "center",
      disablePadding: false,
      label: "Atualização",
    },
    {
      id: "actions",
      numeric: true,
      align: "right",
      disablePadding: false,
      label: "Ações",
    },
  ];

  function handleChangePage(event, newPage) {
    setPage(newPage);
    headerElement.current.scrollIntoView({ behavior: 'smooth' })
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    headerElement.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item" ref={headerElement}>
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Cadastros</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/registers`}
              >
                Cadastros
              </Link>
            </div>
          </div>
          <RegisterSearch
            open={openSearch}
            setOpen={(e) => setOpenSearch(e)}
            getConsult={(e) => submitSearchCnpj(e.value, e.by)}
          />
          <div className="kt-subheader__toolbar">
            <div className="d-flex flex-row kt-subheader__wrapper">
              <div
                style={{
                  background: "#fff",
                  borderRadius: "5px",
                  padding: "5px 10px 10px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <FormControl>
                  <InputLabel>Filtrar Status</InputLabel>
                  <Select
                    value={searchStatus || "Todos"}
                    placeholder="Todos"
                    onChange={(event) => {
                      setSearchStatus(event.target.value);
                      localStorage.setItem(
                        "filter@registers",
                        event.target.value
                      );
                      submitSearchStatus(event.target.value);
                    }}
                    label="Filtrar Status"
                    style={{ width: "150px" }}
                  >
                    <MenuItem value="Todos">Todos</MenuItem>
                    <MenuItem value="0">Em análise</MenuItem>
                    <MenuItem value="1">Finalizado</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <button
                  type="button"
                  onClick={() => setOpenSearch(true)}
                  className="btn btn-light"
                  style={{ width: "100%" }}
                >
                  Buscar{" "}
                  <i
                    className="fa fa-search"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </button>
                {filter && (
                  <button
                    type="button"
                    onClick={() => {
                      loadRegisters();
                      setFilter(false);
                      setSearchStatus("Todos");
                      localStorage.setItem("filter@registers", "Todos");
                      submitSearchStatus("Todos");
                    }}
                    className="btn btn-warning"
                    style={{ width: "100%" }}
                  >
                    Remover Filtro{" "}
                    <i
                      className="fas fa-times"
                      style={{ marginLeft: "10px" }}
                    ></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="bg-white p-3 radius-4 shadow-sm">
          {loading ? <LinearProgress /> : null}
          <table className="w-100 table table-hover">
            <thead>
              <tr>
                {headRows.map(head => (
                  <th key={head.id}>{head.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {formatName(row.name)}
                      </td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        {normalizeCnpj(row.cnpj)}
                      </td>
                      <td>
                        {row.email
                          ? row.email
                          : row.customer && row.customer.email}
                        <br />
                        {row.phone
                          ? normalizePhone(row.phone)
                          : row.customer &&
                            row.customer.phone &&
                            normalizePhone(row.customer.phone)}
                      </td>
                      <td>
                        {gerentes.find(g => g.GERE_ID === Number(row.gerente_id))?.NOME || 'SEM GERENTE'}
                        /<br />
                        {row.user?.fullname || 'SEM AGENTE'}
                      </td>
                      <td className="text-center">
                        {progressRegister(row)}
                      </td>
                      <td className="text-center">
                        {statusRegisterCompleted(row.completed)}
                      </td>
                      <td className="text-center">
                        {format(new Date(row.updated_at), "dd/MM/yyyy")}
                      </td>
                      <td className="text-right">
                        <Link
                          to={`/registers/${row.id}`}
                          className="btn btn-sm pl-2 pr-2"
                        >
                          <VisibilityIcon />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              {!loading && rows.length === 0 ? (
                <tr>
                  <td colSpan={8} className="text-center p-5">
                    Nenhum registro
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 1000]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
}
