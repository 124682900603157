import React, { memo } from "react";
import verifyProfiles from "../../../utils/verifyProfiles";

function CompanyDataNavBar(props) {
  return (
    <div
      style={{
        justifyContent: "flex-end",
        display: "flex",
        width: "100%"
      }}
    >
      {!props.editField.company &&
        !props.selectReprove.fields &&
        props.register.finished !== "A" &&
        (verifyProfiles(props.user, "analist") ||
        verifyProfiles(props.user, "manager_register_confirmation")) && (
          <button
            type="button"
            className="btn btn-info"
            onClick={() =>
              props.setEditField({ ...props.editField, company: true })
            }
            style={{ marginRight: "5px" }}
          >
            Editar Campos
          </button>
        )}
      {props.editField.company && (
        <>
          <button
            type="button"
            className="btn btn-info"
            onClick={() =>
              props.setEditConfirmField({
                ...props.editConfirmField,
                company: true
              })
            }
            style={{ marginRight: "5px" }}
          >
            Atualizar
          </button>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              props.setEditField({ ...props.editField, company: false });
              props.loadRegister();
            }}
          >
            Cancelar
          </button>
        </>
      )}
      {props.selectReprove.fields ? (
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => props.setOpenReprove(true)}
        >
          Reprovar Campos
        </button>
      ) : (
        props.register.finished !== "A" &&
        !props.editField.company &&
        (verifyProfiles(props.user, "analist") ||
        verifyProfiles(props.user, "manager_register_confirmation")) && (
          <button
            type="button"
            className="btn btn-success"
            disabled={props.loading}
            onClick={() => {
              props.setConfirmApprovedCompany(true);
            }}
          >
            Aprovar
          </button>
        )
      )}
      {props.register.finished !== "A" && !props.editField.company && 
      (verifyProfiles(props.user, "analist") ||
      verifyProfiles(props.user, "manager_register_confirmation")) &&
      (
        <button
          type="button"
          className={`btn ${props.selectReprove.fields ? "btn-secondary" : "btn-danger"
            }`}
          onClick={() =>
            props.setSelectReprove({
              ...props.selectReprove,
              fields: !props.selectReprove.fields
            })
          }
          style={{ marginLeft: "5px" }}
        >
          {props.selectReprove.fields ? "Cancelar" : "Reprovar Campos"}
        </button>
      )}
    </div>
  );
}

export default memo(CompanyDataNavBar);
