import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  FormControl,
  InputLabel,
  CircularProgress,
  Paper,
  LinearProgress,
  makeStyles,
  Select,
  MenuItem
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ModalDetailCharge from './components/ModalDetailCharge';
import ModalSearch from './components/ModalSearch';
import { Dropdown, Pagination, ProgressBar } from "react-bootstrap";
import { getPercentages, getProgress, normalizeCnpj } from "./utils/normalize";
import { format } from 'date-fns';
import { toast } from 'react-toastify';

export default function ChargeIndex() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState({
    id: null,
    open: false
  });
  const [search, setSearch] = useState(false);

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: 20,
    },
  }));
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  async function loadCharge() {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/charge/operations`, {
        params: {
          page: page - 1,
          perPage: rowsPerPage
        }
      });
      setRows(response.data.operations);
      setTotal(response.data.total);
    }catch(err) {
      toast.error('Ocorreu um erro na listagem');
    }
    setLoading(false);
    // setRows(response.data);
  }

  async function searchRequest({ by, value }) {
    setLoading(true);
    setFilter(true);
    try {
      if(by === 0) {
        const response = await axios.get(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/charge/operations`, {
          params: {
            page: page - 1,
            perPage: rowsPerPage,
            flag: 'CNPJ',
            value: value.replace(/[^\d]/g, '')
          }
        });
        setRows(response.data.operations);
        setTotal(response.data.total);
      }else {
        const response = await axios.get(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/charge/operations`, {
          params: {
            page,
            perPage: rowsPerPage,
            flag: 'BORDERO',
            value: Number(value.replace(/[^\d]/g, ''))
        }});
        setRows(response.data.operations);
        setTotal(response.data.total);
      }
    } catch(err) {
      if(err.response && err.response.status === 404) {
        toast.info('Nenhum borderô encontrada');
      }else{
        toast.error('Ocorreu um erro ao buscar borderôs');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadCharge();
  }, [page, rowsPerPage]); // eslint-disable-line
  
  const headRows = [
    { id: 'operation', align: "left", disablePadding: true, label: 'Borderô' },
    { id: 'cedent', align: "left", disablePadding: false, label: 'Cedente' },
    { id: 'sacados', align: "left", disablePadding: false, label: 'Sacados' },
    { id: 'progress', align: "center", disablePadding: false, label: (
      <div>
          <Dropdown>
            <Dropdown.Toggle
              variant="link"
              style={{ background: "none", color: "#444" }}
              size="sm"
            >
              Progresso
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="d-flex flex-column justify-content-start align-items-start pl-3 pr-3">
                <div className="d-flex align-items-center">
                  <div
                    className="kt-bg-success mr-1"
                    style={{ width: "10px", height: "10px" }}
                  ></div>
                  Lidos
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="kt-bg-brand mr-1"
                    style={{ width: "10px", height: "10px" }}
                  ></div>
                  Enviados
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="mr-1"
                    style={{
                      width: "10px",
                      height: "10px",
                      background: "#ff7d00",
                    }}
                  ></div>
                  Sem E-mail
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="mr-1"
                    style={{
                      width: "10px",
                      height: "10px",
                      background: "#ff4545",
                    }}
                  ></div>
                  Devolvido
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="mr-1"
                    style={{
                      width: "10px",
                      height: "10px",
                      background: "#b00000",
                    }}
                  ></div>
                  Não Enviado
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="mr-1"
                    style={{
                      width: "10px",
                      height: "10px",
                      background: "#6b28aa",
                    }}
                  ></div>
                  SPAM
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="mr-1"
                    style={{
                      width: "10px",
                      height: "10px",
                      background: "#000",
                    }}
                  ></div>
                  Inválido
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="mr-1"
                    style={{
                      width: "10px",
                      height: "10px",
                      background: "#ab1366",
                    }}
                  ></div>
                  Falha ao enviar
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="mr-1"
                    style={{
                      width: "10px",
                      height: "10px",
                      background: "#ebedf2",
                    }}
                  ></div>
                  Enviando
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
    ) },
    { id: 'date', align: "right", disablePadding: false, label: 'Data do Envio' },
    { id: 'actions', align: "right", disablePadding: false, label: 'Ações' },
  ];
  
  function EnhancedTableHead(props) {
    const { order, orderBy, /*onRequestSort*/ } = props;
    /* const createSortHandler = property => event => {
      onRequestSort(event, property);
    }; */
  
    return (
      <TableHead>
        <TableRow>
          {headRows.map(row => (
            <TableCell
              key={row.id}
              align={row.align}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={() => {}}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleNextPage() {
    const nextPage = page + 1
    setPage(nextPage);
  }

  function handlePreviousPage() {
    const previousPage = page - 1
    setPage(previousPage);
  }

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Operação: Notificação / Cobrança
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/agents`}>
                Operação: Notificação / Cobrança
              </Link>
            </div>
          </div>
          <div className="kt-subheader__toolbar">
            <div className="d-flex flex-row kt-subheader__wrapper">
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: '10px' }}>
              <button type="button" className="btn btn-light" onClick={() => setSearch(true)}>
                  Buscar
                  <i className="fa fa-search" style={{ marginLeft: "10px" }}></i>
                </button>
                {filter && (
                <button type="button" className="btn btn-warning" onClick={() => {
                  setFilter(false);
                  loadCharge();
                }}>
                  Esvaziar Filtro
                </button>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalSearch open={search} setOpen={(e) => setSearch(e)} getConsult={({ by, value }) => searchRequest({ by, value })}/>
      <ModalDetailCharge open={view.open} setOpen={e => {
        setView({
          open: false,
          id: null
        })
      }} id={view.id} onResend={loadCharge}/>
      <Paper className={classes.paper}>
        <div>
        {loading && <LinearProgress color="primary" />}
          <Table
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row._id}
                    >
                      <TableCell align="left">{row.operation}</TableCell>
                      <TableCell align="left">{row.guarantor} <br></br>
                      CNPJ: {normalizeCnpj(row.guarantor_document)}</TableCell>
                      <TableCell align="left">{row.payers.length}</TableCell>
                      <TableCell align="center">
                        {row.operation_group_sends && row.operation_group_sends.map(group => {
                          const events = []
                          group.sends.forEach(s => {
                            if (s.status) {
                              let d = s.status
                              if (d === 'sent') {
                                d = 'processed'
                              } else if (d === 'clicked') {
                                d = 'opened'
                              }
                              events.push(d)
                            }
                          })
                          const arrData = getPercentages(events).sort(
                            (a, b) => b.percentage - a.percentage
                          )
                          return (
                            <div
                            className="d-flex align-items-center mt-1 mb-1"
                            key={group._id}
                          >
                            <small className="mr-1">
                              {group.sends.length} Envio(s) -{' '}
                              {group.date_processing
                                ? format(
                                    new Date(group.date_processing),
                                    'dd/MM/yyyy HH:mm'
                                  )
                                : ''}
                            </small>
                            <ProgressBar style={{ width: '200px' }}>
                              {arrData.map((d, index) =>
                                getProgress(d.event, d.percentage, index)
                              )}
                            </ProgressBar>
                          </div>
                          )
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {format(new Date(row.createdAt), "dd/MM/yyyy 'às' HH:mm")}
                      </TableCell>
                      <TableCell align="right">
                        <button
                          type="button"
                          className="btn btn-sm pl-2 pr-2"
                          onClick={() => {
                            setView({
                              id: row._id,
                              open: true
                            });
                          }}>
                          <VisibilityIcon />
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {rows && rows.length > 0 && (
          <div className="align-items-end d-flex justify-content-between mt-5">
            <span>{total} Total</span>
            <div className="d-flex align-items-center">
              {loading && (
                <CircularProgress size={15} className="mr-2" />
              )}
            <Pagination>
              {page > 1 && (
                <>
                  <Pagination.First onClick={() => setPage(1)} disabled={page === 1 || loading} />
                  <Pagination.Prev onClick={() => handlePreviousPage()} disabled={loading}/>
                </>
              )}
              <Pagination.Item disabled>{page}</Pagination.Item>
              {page < (Math.ceil(total/rowsPerPage) - 1) && (
                <>
                <Pagination.Next onClick={() => handleNextPage()} disabled={loading}/>
                <Pagination.Last onClick={() => setPage(Math.ceil(total/rowsPerPage) - 1)} disabled={loading}/>
                </>
              )}
            </Pagination>
            </div>
            <FormControl
                style={{ width: "100%", maxWidth: "120px", marginRight: "10px" }}
                disabled={loading}
              >
                <InputLabel htmlFor="results_per_page">Result. p/Página</InputLabel>
                <Select
                  id="results_per_page"
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(e.target.value)}
                  disabled={loading}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
          </div>
        )}
      </Paper>
    </div>
  );
}
