import React from "react";
import { Route, Switch } from "react-router-dom";
import ChargeIndex from "./ChargeIndex";

export default function CnpjSearchPage() {
  return (
    <Switch>
      <Route exact path="/charge_v2" component={ChargeIndex} />
    </Switch>
  );
}
