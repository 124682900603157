import React, { memo } from "react";
import verifyProfiles from "../../../utils/verifyProfiles";

function NothingSelected(props) {
  return (
    <>
      {
        (verifyProfiles(props.user, "analist") ||
          verifyProfiles(props.user, "manager_register_confirmation")) && (
          <div>
            <button
              type="button"
              className="btn btn-info mr-3"
              onClick={() => props.setSelectExempt(!props.selectExempt)}
            >
              Selecionar para Isentar
            </button>

            <button
              type="button"
              className="btn btn-success mr-3"
              onClick={() => props.setSelectApprove(!props.selectApprove)}
            >
              Selecionar para Aprovar
            </button>

            <button
              type="button"
              className="btn btn-danger mr-3"
              onClick={() =>
                props.setSelectReprove({
                  ...props.selectReprove,
                  docs: !props.selectReprove.docs
                })
              }
            >
              Selecionar para Negar
            </button>
          </div>
        )
      }
    </>
  );
}

export default memo(NothingSelected);
