import React, { useState, useEffect } from "react";

import {
  Modal,
  Fade,
  Backdrop,
  makeStyles,
} from "@material-ui/core";

export default function ConfirmationNewByCnpj({ open, setOpen, getConsult }) {
  const [value, setValue] = useState("");
  const [by, setBy] = useState(1);

  async function consultcnpj(e) {
    e.preventDefault();
    getConsult({ by, value });
    setOpen(false);
    setValue("");
  }

  useEffect(() => {
    setValue("");
  }, [by]);

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "600px",
    },
  }));
  const classes = useStyles();
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(false);
          setBy(0);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fade}>
            <h2 id="transition-modal-title">Buscar</h2>
            <hr />
            {by !== null && (
              <>
                <h4 className="mt-4" style={{ textAlign: "center" }}>
                  Buscar Cadastros por:
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="button"
                    className={`btn btn-sm btn-${
                      by === 0 ? "dark" : "secondary"
                    } mr-2`}
                    onClick={() => setBy(0)}
                    disabled={by === 0}
                  >
                    CNPJ
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm btn-${
                      by === 1 ? "dark" : "secondary"
                    } mr-2`}
                    onClick={() => setBy(1)}
                    disabled={by === 1}
                  >
                    Nome
                  </button>
                </div>
              </>
            )}
            {by === 0 && (
              <form onSubmit={consultcnpj}>
                <input
                  placeholder="CNPJ"
                  className="form-control mt-4"
                  value={value}
                  onChange={(e) => {
                    const onlyNums = e.target.value.replace(/[^\d]/g, "");
                    if (onlyNums.length <= 2) {
                      setValue(onlyNums);
                    } else if (onlyNums.length <= 5) {
                      setValue(
                        `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}`
                      );
                    } else if (onlyNums.length <= 8) {
                      setValue(
                        `${onlyNums.slice(0, 2)}.${onlyNums.slice(
                          2,
                          5
                        )}.${onlyNums.slice(5, 8)}`
                      );
                    } else if (onlyNums.length <= 12) {
                      setValue(
                        `${onlyNums.slice(0, 2)}.${onlyNums.slice(
                          2,
                          5
                        )}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}`
                      );
                    } else {
                      setValue(
                        `${onlyNums.slice(0, 2)}.${onlyNums.slice(
                          2,
                          5
                        )}.${onlyNums.slice(5, 8)}/${onlyNums.slice(
                          8,
                          12
                        )}-${onlyNums.slice(12, 14)}`
                      );
                    }
                  }}
                />
                <div className="d-flex justify-content-center mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary kt-subheader__btn-options mr-4"
                    disabled={value.length === 0}
                  >
                    Pesquisar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light kt-subheader__btn-options"
                    onClick={() => {
                      setOpen(false);
                      setBy(0);
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            )}
            {by === 1 && (
              <form onSubmit={consultcnpj}>
                <input
                  placeholder="Cedente"
                  className="form-control mt-4"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                />
                <div className="d-flex justify-content-center mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary kt-subheader__btn-options mr-4"
                    disabled={value.length === 0}
                  >
                    Pesquisar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light kt-subheader__btn-options"
                    onClick={() => {
                      setOpen(false);
                      setBy(0);
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
}
