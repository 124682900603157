import { format, subHours, addHours } from "date-fns";

function dateByTimezone(date) {
  const timezone = new Date().getTimezoneOffset() / 60;
  if(timezone >= 0){
    return subHours(new Date(date), timezone)
  }
  return addHours(
    new Date(date),
    Math.abs(timezone)
  )
}

export default function impressOpinion(data, company) {
  var mywindow = window.open('', 'PRINT');

  const { opinions, approved } = data;

  function setProfile(profile) {
    switch(profile) {
      case 1: return 'Administrador';
      case 2: return 'Diretor';
      case 3: return 'Gerente';
      case 4: return 'Analista';
      default: return '-'; 
    }
  }

  mywindow.document.write(`
  <!DOCTYPE html>
  <html lang="pt-BR">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Parecer</title>
    <style>
      body {
        font-family: sans-serif;
      }
      div.opinion {
        padding:20px;
        font-size: 12px;
      }
      div.opinion + div.opinion {
        border-top:1px solid #eee;
      }
      div.opinion:nth-child(odd) {
        background: #f9f9f9;
      }
      div.opinion p{
        margin: 0px;
        margin-top: 6px;
      }
      div.opinion i {
        display: block;
        text-align: right;
        margin-top: 6px;
      }
      ul {
        list-style:none;
        margin: 0;
        padding:0;
      }
      ul li {
        margin:0;
        padding:0;
      }
      .approvals {
        padding: 15px;
      }
    </style>
  </head>
  <body>
  <div style="display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #c9c9c9;padding: 16px;">
    <img src="https://app.grupobfc.com.br/media/logos/logo-4-blue.png">
      <div style="
    text-align: right;
    color: #09569c;
    ">
          <h4 style="
    margin: 0;
    ">${company.name}</h4>
          <h3 style="
    margin: 0;
    ">Pareceres</h3>
      </div>
    </div>
    <div>
      ${opinions.length > 0 && opinions.map(opinion => {
        return `
          <div class='opinion'>
            <strong>${opinion.user.fullname} - ${setProfile(opinion.user.profile)}</strong>
            <p>${opinion.message}</p>
            <i>${format(dateByTimezone(opinion.created_at), "dd'/'MM'/'yyyy 'às' HH':'mm")}</i>
          </div>
        `;
      }).join('')}
    </div>
    <div class="approvals">
      <h3>Aprovado por:</h3>
      <ul>
      ${approved.length > 0 && approved.map(approve => {
        return `
          <li><strong>${approve.user.fullname}</strong> - ${format(dateByTimezone(approve.created_at), "dd/MM/yyyy 'às' HH:mm")}</li>
        `;
      }).join('')}
      </ul>
    </div>
  </body>
  </html>
  `);
  mywindow.document.close();
  mywindow.focus(); 

  setTimeout(() => {
    mywindow.print();
    mywindow.close();
  }, 500);
}