import React, { memo, useEffect, useState } from "react";

import { normalizeCpfCnpj } from "../utils/normalize";
import ProtestItem from "./ProtestItem";

import axios from 'axios';
import { cpfIsValid } from "../../protest/utils";
import { toast } from "react-toastify";

import { CircularProgress } from "@material-ui/core";

function ItemDetail({ e, index, detail }) {
  const [protests, setProtests] = useState([]);
  const [loading, setLoading] = useState(false)

  async function resendItem() {
    setLoading(true)
    try {
      await axios.post(`https://gateway-ovh.grupobfc.com.br/payerProtest`, {
        borderoId: detail.borderoId,
        borderoType: "not-paid",
        assignorName: detail.assignorName,
        assignorDocumentNumber: detail.assignorDocumentNumber,
        payers: [
          {
            payerName: e.name,
              payerDocumentNumber:
              e.documentNumber.slice(0, 3) === '000' && cpfIsValid(e.documentNumber.slice(3, e.documentNumber.length))
                  ? e.documentNumber.slice(3, e.documentNumber.length)
                  : e.documentNumber,
          }
        ],
        payersCount: detail.payers.length,
      });
      e.status = 'pending';
      toast.info('Sacado Reenviado para análise de Protesto');
    }catch(err) {
      toast.error('Ocorreu um erro inesperado, contate o suporte técnico')
    }
    setLoading(false)
  }

  useEffect(() => {
    let protestsArr = [];

    if (!e.protests) {
      setProtests(null)
      return
    }

    e.protests.forEach(p => {
      let json = p.json
      if(typeof json === 'string') {
        json = JSON.parse(p.json)
      }
      const stringP = JSON.stringify({ ...p, dateConsult: undefined, json: { ...json, dataConsulta: undefined }, _id: undefined });
      if (!protestsArr.find(i => i.string === stringP)) {
        protestsArr.push({
          dates: [json.dataConsulta.slice(0, 10)],
          obj: p,
          string: stringP,
        });
      } else {
        protestsArr = protestsArr.map(i => {
          if (i.string === stringP) {
            return {
              ...i,
              dates: [...i.dates, json.dataConsulta.slice(0, 10)]
            }
          }
          return i;
        })
      }
    });

    setProtests(protestsArr.map(p => {
      return {
        ...p.obj,
        dates: p.dates,
      }
    }));
  }, [e, e.protests]);

  return (
    <div
      key={index}
      style={{
        padding: "15px",
        border: "1px solid #eee",
        margin: "10px 0px",
        background: "#f9f9f9",
        position: "relative",
        boxShadow: "0px 0px 5px rgb(0 0 0 / 10%)"
      }}
    >
      <div
        className="d-flex justify-content-between align-items-start"
      >
        <div>
          <h4 className="d-flex align-items-center">
            {e.status === 'pending' && (
              <span style={{ width: '12px', height: '12px', borderRadius: '50%', background: 'orange', display: 'block', marginRight: '6px' }} />
            )}
            {e.status === 'error' && (
              <span style={{ width: '12px', height: '12px', borderRadius: '50%', background: 'purple', display: 'block', marginRight: '6px' }} />
            )}
            {e.status !== 'pending' && e.status !== 'error' && e.protests && e.protests[0].amountTitles > 0 && (
              <span style={{ width: '12px', height: '12px', borderRadius: '50%', background: 'red', display: 'block', marginRight: '6px' }} />
            )}
            {e.status !== 'pending' && e.status !== 'error' && e.protests && e.protests[0].amountTitles === 0 && (
              <span style={{ width: '12px', height: '12px', borderRadius: '50%', background: 'green', display: 'block', marginRight: '6px' }} />
            )}
            {e.name} - {normalizeCpfCnpj(e.documentNumber)}
          </h4>
        </div>
      </div>
      {loading && (
        <CircularProgress size={10}/>
      )}
      {!e.status && (
        <button type="button" className="btn btn-sm btn-outline-primary mb-2 mt-2 btn-upper" onClick={resendItem} disabled={loading}>Reenviar</button>
      )}
      {e.status === 'pending' && (
        <p className="mb-0">
          Em processamento
        </p>
      )}
      {e.status === 'error' && (
        <p className="mb-2 text-danger">
          Erro no Ultimo Processsamento <button type="button" className="btn btn-sm btn-outline-danger ml-2 btn-upper" onClick={resendItem} disabled={loading}>Reenviar</button>
        </p>
      )}
      {e.protests && protests.length > 0 && protests.map((protest, index) => {
        let json = protest.json
        if(typeof json === 'string') {
          json = JSON.parse(json)
        }
          if (protest.amountTitles === 0) {
            return (
              <p key={index} className="mb-0">
                <strong><i className="far fa-calendar-alt mr-1"></i> {json.dataConsulta.slice(0, 10)}</strong> Nenhum título em protesto
              </p>
            );
          }
          return (
            <ProtestItem key={index} protest={{...protest, json}} />
          )
        })}
    </div>
  );
}

export default memo(ItemDetail);
