import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { Paper, Button, CircularProgress, LinearProgress, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Mail, Visibility } from "@material-ui/icons";

import axios from 'axios';
import { toast } from 'react-toastify';
import { Table, Pagination } from "react-bootstrap";

import { format } from 'date-fns';

import statusRegisterCompleted from "./utils/statusRegisterCompleted";

import MailSender from "./components/MailSender";
import Detail from "./components/Detail";

function MailPage() {
  const [sendMail, setSendMail] = useState(false);
  const [openDetail, setOpenDetail] = useState({
    active: false,
    item: {},
  });
  const [user, setUser] = useState({
    email: '',
    fullname: '',
    id: '',
    innactivated: '',
    pic: '',
    profile: '',
    roles: []
  });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [avulses, setAvulses] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { data: userData } = await axios.get('auth');

        setUser(userData);
        const { data } = await axios.get(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/charge/avulses?page=${page}&perPage=${perPage}&origin=notification`);
        setAvulses(data);
      }catch(err) {
        toast.error(err.message);
      }
      setLoading(false);
    }
    loadData();
  }, [page, perPage]);

  function handlePreviousPage() {
    setPage(page - 1);
  }

  function handleNextPage() {
    setPage(page + 1);
  }

  return (
    <>
    {sendMail && (
      <MailSender open={sendMail} setOpen={(e) => setSendMail(e)} onSend={() => setPage(0)} user={user}/>
    )}
      <Detail
        open={openDetail.active}
        setOpen={() => setOpenDetail({
          active: false,
          item: {},
        })}
        detail={openDetail.item}
      />
      <div style={{ width: "100%" }}>
        <div className="kt-subheader kt-grid__item">
          <div className="kt-container ">
            <div className="kt-subheader__main">
              <h3 className="kt-subheader__title">Envio de E-mail</h3>
              <div className="kt-subheader__breadcrumbs">
                <Link to="/" className="kt-subheader__breadcrumbs-home">
                  <i className="flaticon2-shelter" />
                </Link>
                <span className="kt-subheader__breadcrumbs-separator" />
                <Link className="kt-subheader__breadcrumbs-link" to={`/mail`}>
                  Envio de E-mail
                </Link>
              </div>
            </div>
            <div className="kt-subheader__toolbar">
              <div className="kt-subheader__wrapper d-flex align-items-center">
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  startIcon={<Mail />}
                  onClick={() => setSendMail(true)}
                >
                  Enviar E-mail
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Paper
          style={{ padding: "40px", width: "100%" }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          {loading && (
            <LinearProgress />
          )}
          <Table hover>
            <thead>
              <tr>
                <th>Assunto</th>
                <th>Destinatário</th>
                <th>Mensagem</th>
                <th className="text-center">Status</th>
                <th className="text-center">Data de envio</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {avulses.map(avulse => (
                <tr key={avulse._id}>
                  <td style={{ width: '226px' }}>{avulse.subject}</td>
                  <td style={{ wordBreak: 'break-all', width: '282px' }}>{avulse.email}</td>
                  <td>{avulse.message.slice(0, 40)}{avulse.message.length > 40 && '...'}</td>
                  <td className="text-center">{avulse.events.length > 0 ? statusRegisterCompleted(avulse.events[avulse.events.length - 1].event) : 'Enviando...'}</td>
                  <td className="text-center">{format(new Date(avulse.createdAt), 'dd/MM/yyyy - HH:mm')}</td>
                  <td className="text-right">
                    <button
                      type="button"
                      style={{ background: "none", border: "none" }}
                      onClick={() => setOpenDetail({
                        active: true,
                        item: avulse,
                      })}
                      disabled={loading}
                    >
                      <Visibility />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div style={{ width: '100%' }} className="align-items-end d-flex justify-content-between mt-5">
            <span></span>
            <div className="d-flex align-items-center">
              {loading && (
                <CircularProgress size={15} className="mr-2" />
              )}
            <Pagination>
              {page > 0 && (
                <>
                  <Pagination.First onClick={() => setPage(0)} disabled={page === 0 || loading} />
                  <Pagination.Prev onClick={() => handlePreviousPage()} disabled={loading}/>
                </>
              )}
              <Pagination.Item disabled>{page + 1}</Pagination.Item>
                <Pagination.Next onClick={() => handleNextPage()} disabled={loading}/>
            </Pagination>
            </div>
            <FormControl
                style={{ width: "100%", maxWidth: "120px", marginRight: "10px" }}
                disabled={loading}
              >
                <InputLabel htmlFor="results_per_page">Result. p/Página</InputLabel>
                <Select
                  id="results_per_page"
                  value={perPage}
                  onChange={(e) => setPerPage(e.target.value)}
                  disabled={loading}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={250}>250</MenuItem>
                  <MenuItem value={1000}>1000</MenuItem>
                </Select>
              </FormControl>
          </div>
        </Paper>
      </div>
    </>
  );
}

export default MailPage;
