import React from 'react'
import { ProgressBar } from 'react-bootstrap'

export const normalizeCnpj = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2,5)}`;
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2,5)}.${onlyNums.slice(5, 8)}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}`;
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

export const getNotificationStyle = (
  type
) => {
  const style = {
    backgroundColor: '#eee',
    color: '#fff'
  }
  if (type === 'opened' || type === 'clicked') {
    style.backgroundColor = '#1dc9b7'
  } else if (type === 'delivered') {
    style.backgroundColor = '#366cf3'
  } else if (type === 'soft_bounced') {
    style.backgroundColor = '#ff4545'
  } else if (type === 'hard_bounced') {
    style.backgroundColor = '#b00000'
  } else if (type === 'bounce') {
    style.backgroundColor = '#000'
  } else if (type === 'no_content') {
    style.backgroundColor = 'rgb(255, 125, 0)'
  } else if (type === 'unknown') {
    style.backgroundColor = '#ab1366'
  } else if (type === 'spam_complaint') {
    style.backgroundColor = '#6b28aa'
  } else {
    style.color = '#444'
    style.backgroundColor = '#eee'
  }
  return style
}
export const getProgress = (
  type,
  percentage,
  key
) => {
  const style = getNotificationStyle(type)
  return (
    <ProgressBar
      now={percentage}
      style={{ backgroundColor: style.backgroundColor, color: style.color }}
      label={percentage > 7 ? `${percentage}%` : ''}
      key={key}
    />
  )
}

export const getPercentages = (
  statuses
) => {
  const obj = {}
  statuses.forEach(status => {
    if (obj[status]) {
      obj[status] += 1
    } else {
      obj[status] = 1
    }
  })
  const arr = []
  Object.keys(obj).forEach(
    (
      key
    ) => {
      arr.push({
        event: key,
        percentage: Math.round((obj[key] * 100) / statuses.length)
      })
    }
  )
  return arr
}

export const formatEvent = (
  e,
  onlyText
) => {
  let title = ''
  if (e === 'soft_bounced') {
    title = 'Devolvido'
  } else if (e === 'hard_bounced') {
    title = 'Não Entregue'
  } else if (e === 'spam_complaint') {
    title = 'SPAM'
  } else if (e === 'delivered') {
    title = 'Enviado'
  } else if (e === 'clicked' || e === 'opened') {
    title = 'Lido'
  } else if (e === 'no_content') {
    title = 'Sem e-mail'
  } else if (e === 'bounce') {
    title = 'Inválido'
  } else if (e === 'unknown') {
    title = 'Falha ao Enviar'
  } else {
    title = 'Enviando'
  }
  if(onlyText) {
    return title
  }
  const style = getNotificationStyle(e)
  return (
    <span className="pl-2 pr-2 pt-0 pb-0 radius-4 small" style={{...style, whiteSpace: 'nowrap'}}>
      {title}
    </span>
  )
}
