export default function printChargeReportEmail({ createdAt, createdAtComplete, events, situation, sacado }) {
  var mywindow = window.open('', 'PRINT');

  mywindow.document.write(`
  <html>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
  <style>
    * {
      font-family: sans-serif;
      color: #2e3557;
    }

    h1 {
      font-weight: normal;
    }

    #grid_boletos {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 40px;
      border-left: 4px solid #e4c147;
      padding-left: 20px;
    }

    #grid_boletos span {
      font-size: 12px;
    }

    #about_tr h1 {
      margin: 0;
    }

    #about_tr p {
      text-align: right;
      margin: 0;
      margin-right: 15px;
      margin-top: 10px;
    }

    #about_tr img {
      margin-top: 35px;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 12px;
      font-family: 'Roboto';
      margin: 0 0 20px;
    }
    .header > div.first p {
      margin: 0 0 5px;
    }
    .header > div.first p strong {
      font-weight: 700;
    }
    .header > div.first  span {
      text-decoration: underline;
      font-weight: 400;
      color: #95576e;
    }
    .body {
      padding: 10px;
    }
    .body h3 { 
      margin-top: 0px;
      margin-bottom: 30px;
    }
    .body p {
      font-size: 12px;
      margin: 0 0 7.5px;
      padding-bottom: 0px;
    }
    .body p span {
      text-decoration: underline;
      font-weight: 400;
      color: #95576e;
    }
  </style>
  <div class="body">
    <div class="header">
      <div class="first">
        <p>
        <strong>De: </strong>BFC - NoReply [<span>maito:bfc@grupobfc.com.br</span>]
        </p>
        <p>
        <strong>Enviado em: </strong>${createdAtComplete}
        </p>
        <p>
        <strong>Para: </strong><span>${sacado.email}</span>
        </p>
        <strong><p>Assunto: </strong>${situation}: Notificação de ${events.length} Boleto(s) do cedente: ${sacado.assignor} Sacado: ${sacado.name}</p>
      </div>
    </div>
    <table cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td colspan="2" class="body">
            <br/>
            <h3>Recibo de ${situation}</h3>
            <p>Enviado para: <span>${sacado.email}</span> </p>
            <p>Data/Hora: ${createdAt}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  </html>
  `);
  mywindow.document.close();
  mywindow.focus(); 
  setTimeout(() => {
    mywindow.print();
    mywindow.close();
  }, 1500)
}