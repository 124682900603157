import React, { useState, useEffect } from "react";

import {
  Modal,
  Fade,
  Backdrop,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";

export default function ConfirmationNewByCnpj({ open, setOpen, getConsult }) {
  const [value, setValue] = useState("");
  const [by, setBy] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  async function consultcnpj(e) {
    e.preventDefault();
    getConsult({ by, value });
    setOpen(false);
    setValue("");
  }

  useEffect(() => {
    setValue("");
  }, [by]);

  useEffect(() => {
    async function loadUsers() {
      try {
        const { data } = await axios.get(`/roles/users/8`);
        setUsers(data);
      } catch (err) {}
    }
    loadUsers();
  }, []);

  async function searchRequest(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/registers/razao-social/${value}`
      );
      setCompanies(response.data.registers);
      setBy(null);
    } catch (err) {
      if (err.response.status === 404) {
        toast.error("Nenhum cadastro encontrado.");
      } else {
        toast.error("Ocorreu um erro, tente novamente.");
      }
    }
    setLoading(false);
  }

  function handleSelectCompany(company) {
    setCompanies([]);
    setBy(0);
    setOpen(false);
    getConsult({ by: 0, value: company.cnpj });
    setValue("");
  }

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "600px",
    },
  }));
  const classes = useStyles();
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(false);
          setBy(0);
          setCompanies([]);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fade}>
            <h2 id="transition-modal-title">Buscar</h2>
            <hr />
            {by !== null && (
              <>
                <h4 className="mt-4" style={{ textAlign: "center" }}>
                  Buscar Cadastros por:
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="button"
                    className={`btn btn-sm btn-${
                      by === 0 ? "dark" : "secondary"
                    } mr-2`}
                    onClick={() => setBy(0)}
                    disabled={by === 0}
                  >
                    CNPJ
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm btn-${
                      by === 1 ? "dark" : "secondary"
                    } mr-2`}
                    onClick={() => setBy(1)}
                    disabled={by === 1}
                  >
                    Cedente
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm btn-${
                      by === 2 ? "dark" : "secondary"
                    } mr-2`}
                    onClick={() => setBy(2)}
                    disabled={by === 2}
                  >
                    Agente
                  </button>
                </div>
              </>
            )}
            {by === 0 && (
              <form onSubmit={consultcnpj}>
                <input
                  placeholder="CNPJ"
                  className="form-control mt-4"
                  value={value}
                  onChange={(e) => {
                    const onlyNums = e.target.value.replace(/[^\d]/g, "");
                    if (onlyNums.length <= 2) {
                      setValue(onlyNums);
                    } else if (onlyNums.length <= 5) {
                      setValue(
                        `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}`
                      );
                    } else if (onlyNums.length <= 8) {
                      setValue(
                        `${onlyNums.slice(0, 2)}.${onlyNums.slice(
                          2,
                          5
                        )}.${onlyNums.slice(5, 8)}`
                      );
                    } else if (onlyNums.length <= 12) {
                      setValue(
                        `${onlyNums.slice(0, 2)}.${onlyNums.slice(
                          2,
                          5
                        )}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}`
                      );
                    } else {
                      setValue(
                        `${onlyNums.slice(0, 2)}.${onlyNums.slice(
                          2,
                          5
                        )}.${onlyNums.slice(5, 8)}/${onlyNums.slice(
                          8,
                          12
                        )}-${onlyNums.slice(12, 14)}`
                      );
                    }
                  }}
                />
                <div className="d-flex justify-content-center mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary kt-subheader__btn-options mr-4"
                    disabled={value.length === 0}
                  >
                    Pesquisar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light kt-subheader__btn-options"
                    onClick={() => {
                      setOpen(false);
                      setCompanies([]);
                      setBy(0);
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            )}
            {by === 1 && (
              <form onSubmit={searchRequest}>
                <input
                  placeholder="Cedente"
                  className="form-control mt-4"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                />
                <div className="d-flex justify-content-center mt-4">
                <button
                    type="submit"
                    className="btn btn-primary kt-subheader__btn-options mr-4"
                    disabled={value.length === 0 || loading}
                  >
                    {loading ? (
                      <>
                        <CircularProgress
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "#fff",
                          }}
                        />{" "}
                        Pesquisando
                      </>
                    ) : (
                      "Pesquisar"
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-light kt-subheader__btn-options"
                    onClick={() => {
                      setOpen(false);
                      setCompanies([]);
                      setBy(0);
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            )}
            {by === 2 && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  getConsult({ by, value });
                }}
              >
                <select
                  placeholder="Agente"
                  className="form-control mt-4"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                >
                  <option value="">Escolher Agente</option>
                  {users.map((u) => (
                    <option value={u.id} key={u.id}>
                      {u.fullname}
                    </option>
                  ))}
                </select>
                <div className="d-flex justify-content-center mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary kt-subheader__btn-options mr-4"
                    disabled={value.length === 0 || loading}
                  >
                    Pesquisar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light kt-subheader__btn-options"
                    onClick={() => {
                      setOpen(false);
                      setCompanies([]);
                      setBy(0);
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            )}
            {companies.length > 0 && (
              <div style={{ height: "300px", overflow: "auto" }}>
                <h4 style={{ textAlign: "center" }}>Escolha um cedente</h4>
                {companies.map((company, index) => (
                  <button
                    type="button"
                    key={index}
                    className="btn btn-block"
                    onClick={() => handleSelectCompany(company)}
                    style={{
                      border: "1px solid #c9c9c9",
                      background: "#f9f9f9",
                      padding: "10px",
                      textAlign: "left",
                      margin: "10px",
                    }}
                  >
                    Nome: {company.name}
                    <br />
                    CNPJ: {company.cnpj}
                  </button>
                ))}
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setCompanies([]);
                    setBy(1);
                  }}
                >
                  Voltar
                </button>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
}
