import React, { memo } from "react";
import ExemptSelected from "./documentOptionSelected/ExemptSelected";
import ApproveSelected from "./documentOptionSelected/ApproveSelected";
import ReproveSelected from "./documentOptionSelected/ReproveSelected";
import NothingSelected from "./documentOptionSelected/NothingSelected";

function DocumentsNavBar(props) {
  function selected() {
    if (props.selectExempt && !props.selectApprove && !props.selectReprove) {
      return "exempt";
    } else if (
      !props.selectExempt &&
      props.selectApprove &&
      !props.selectReprove
    ) {
      return "approve";
    } else if (
      !props.selectExempt &&
      !props.selectApprove &&
      props.selectReprove
    ) {
      return "reprove";
    } else {
      return "nothing";
    }
  }

  switch (selected()) {
    case "exempt":
      return (
        <ExemptSelected
          setConfirmExemptedDocument={props.setConfirmExemptedDocument}
          setSelectApprove={props.setSelectApprove}
          setSelectExempt={props.setSelectExempt}
          setSelectReprove={props.setSelectReprove}
        />
      );
    case "approve":
      return (
        <ApproveSelected
          setConfirmApprovedDocument={props.setConfirmApprovedDocument}
          setSelectApprove={props.setSelectApprove}
          setSelectExempt={props.setSelectExempt}
          setSelectReprove={props.setSelectReprove}
        />
      );
    case "reprove":
      return (
        <ReproveSelected
          setOpenReproveDocs={props.setOpenReproveDocs}
          setSelectApprove={props.setSelectApprove}
          setSelectExempt={props.setSelectExempt}
          setSelectReprove={props.setSelectReprove}
        />
      );
    default:
      return (
        <NothingSelected
          setSelectExempt={props.setSelectExempt}
          setSelectApprove={props.setSelectApprove}
          setSelectReprove={props.setSelectReprove}
          selectReprove={props.selectReprove}
          user={props.user}
        />
      );
  }
}

export default memo(DocumentsNavBar);
