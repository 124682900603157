import React from "react";

function CheckBox({ onChange, name, checked }) {
  return (
    <button
      type="button"
      style={{
        background: "none",
        border: 0,
        marginLeft: 0,
        paddingLeft: 0,
      }}
      onClick={onChange}
    >
      <p
        style={{
          padding: "0",
          margin: "0",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          className={checked ? "fa fa-check" : ""}
          style={{
            width: "18px",
            height: "18px",
            border: "0",
            background: "#dee2ea",
            display: "inline-block",
            borderRadius: "5px",
            marginRight: "10px",
          }}
        ></span>
        {name}
      </p>
    </button>
  );
}

export default CheckBox;
