import React, { memo, useState } from "react";

import { format } from "date-fns";
import verifyTitleEvent from "../utils/verifyTitleEvent";

// import { normalizeCnpj } from "../utils/normalize";

function ItemDetail({ e, index, handleSelect, check }) {
  const [expanded, setExpanded] = useState(false);

  console.log('e',e)

  return (
    <button
      key={index}
      style={{
        padding: "15px",
        border: "1px solid #eee",
        margin: "20px 0px",
        background: "#f9f9f9",
        position: "relative",
      }}
      onClick={() => handleSelect(e)}
    >
      <div className="d-flex justify-content-start align-items-start">
        <i
          className="fas fa-building mr-4"
          style={{
            padding: "20px 20px",
            fontSize: "40px",
            background: "#eee",
            borderRadius: "6px",
          }}
        ></i>
        <div style={{ textAlign: "left" }}>
          <h4>{e.name}</h4>
          <p className="mb-1">
            <i className="fas fa-id-card mr-2"></i>
            {e.saca_id}
          </p>
          <p className="mb-1">
            <i className="fas fa-envelope mr-2"></i>
            {e.email || "-"}
          </p>
        </div>
        <div
          className="d-flex"
          style={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <span
            className="d-flex justify-content-center align-items-center"
            style={{
              background: "#eee",
              borderRadius: "4px",
              width: "20px",
              height: "20px",
              margin: "10px 10px 10px 20px",
            }}
          >
            {check && (
              <i className="fas fa-check" style={{ fontSize: "16px" }}></i>
            )}
          </span>
          {e.events && e.events.length > 0 ? (
            <>
              {(e.events[e.events.length - 1].event === "click" ||
                e.events[e.events.length - 1].event === "open") && (
                <span
                  style={{ padding: "10px" }}
                  className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded"
                >
                  Lido
                </span>
              )}
              {(e.events[e.events.length - 1].event === "processed" ||
                e.events[e.events.length - 1].event === "delivered") && (
                <span
                  style={{ padding: "10px" }}
                  className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill kt-badge--rounded"
                >
                  Enviado
                </span>
              )}
              {(e.events[e.events.length - 1].event === "dropped" ||
                e.events[e.events.length - 1].event === "deferred" ||
                e.events[e.events.length - 1].event === "bounce") && (
                <span
                  style={{ padding: "10px" }}
                  className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded"
                >
                  Inválido
                </span>
              )}
            </>
          ) : e.email === "" ? (
            <span
              style={{ padding: "10px" }}
              className="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill kt-badge--rounded"
            >
              Não possui email
            </span>
          ) : (
            <span
              style={{ padding: "10px" }}
              className="kt-badge kt-badge--secondary kt-badge--inline kt-badge--pill kt-badge--rounded"
            >
              Enviando
            </span>
          )}
        </div>
      </div>
      <div
        className="d-flex justify-content-end align-items-center"
        style={{ width: "100%" }}
      >
        <button
          className="btn btn-sm ml-4"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (
            <>
              Ver menos <i className="fas fa-angle-up ml-2"></i>
            </>
          ) : (
            <>
              Ver mais <i className="fas fa-angle-down ml-2"></i>
            </>
          )}
        </button>
      </div>
      <hr />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p>Hash: {e.sg_message_id}</p>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p>
          Data de Processamento:{" "}
          {format(new Date(e.createdAt), "dd/MM/yyyy 'às' HH:mm")}
        </p>
      </div>
      {expanded && (
        <div>
          {e.events && e.events.length > 0 ? (
            <table className="table table-bordered mt-4">
              <thead>
                <tr className="bg-secondary text-dark">
                  <td>E-mail</td>
                  <td>Evento</td>
                  <td>Data</td>
                </tr>
              </thead>
              <tbody>
                {e.events.map((event, index) => (
                  <tr className="bg-white" key={index}>
                    <td>
                      <small>{event.email}</small>
                    </td>
                    <td>
                      <small>{verifyTitleEvent(event.event)}</small>
                    </td>
                    <td>
                      <small>
                        {format(
                          new Date(event.timestamp),
                          "dd/MM/yyyy 'às' HH:mm"
                        )}
                      </small>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <span>Nenhum evento registrado...</span>
          )}
        </div>
      )}
    </button>
  );
}

export default memo(ItemDetail);
