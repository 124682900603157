import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { Paper, Button, CircularProgress, LinearProgress } from "@material-ui/core";
import { MonetizationOn, MoneyOff, DateRange, Search, Sync, Visibility, PictureAsPdf } from "@material-ui/icons";

import { ProgressBar, Table, Pagination, Dropdown } from "react-bootstrap";

import { isAfter, subMonths, format } from "date-fns";
import pt_BR from "date-fns/locale/pt-BR";

import { normalizeCnpj, normalizeCurrency } from "./normalize";

import exportToPdf from './exportToPdf';

import ModalMonths from "./ModalMonths";
import ModalRejects from "./ModalRejects";

import ModalAdd from "./ModalAdd";
import ModalSearch from "./ModalSearch";

import axios from "axios";
import { toast } from "react-toastify";

function RevenuePage() {
  const [eCNPJSelected, setECNPJSelected] = useState({});
  const [lastConsulted, setLastConsulted] = useState([]);
  const [total, setTotal] = useState(0);

  const [openNew, setOpenNew] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage] = useState(100);

  const [positionPerMonth, setPositionPerMonth] = useState(false);
  const [showRejects, setShowRejects] = useState(false);

  const [nfes, setNfes] = useState([]);
  const [rejects, setRejects] = useState([]);
  const [companyData, setCompanyData] = useState({});

  const [reSended, setResended] = useState(false);
  const [remake, setRemake] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (nfes.length > 0) {
      let count = 0;
      nfes.forEach((nfe) => {
        count += nfe.value;
      });
      setTotal(count);
    } else {
      setTotal(0);
    }
  }, [nfes]);

  useEffect(() => {
    async function loadLastConsults() {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/revenues/list/last?page=${page}&perPage=${perPage}`
        );
        setLastConsulted(data);
      } catch (err) {
        if (err.response && err.response.status === 500) {
          toast.error(
            "Ocorreu um erro em nossos servidores ao listar últimas consultas, contate a equipe técnica"
          );
        } else {
          toast.error(
            "Ocorreu um erro em nossa aplicação ao listar últimas consultas, contate a equipe técnica"
          );
        }
      }
      setLoading(false);
    }
    loadLastConsults();
  }, [page, perPage]);

  async function remakeDist() {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/revenues/remakeDist`,
        {
          cnpj: companyData.cnpj.replace(/[^\d]/g, ""),
          uf: companyData.uf,
        }
      );
      setRemake(true);
      toast.info(
        "Reenviado para realizar nova busca por NFE's. Consulte esta empresa novamente daqui a alguns minutos",
        {
          autoClose: 10000,
        }
      );
    } catch (err) {
      if (err.response && err.response.status === 404) {
        toast.warn("e-CNPJ não encontrado no servidor");
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossa consulta ao servidor de CNPJ, contate a equipe técnica"
        );
      } else {
        toast.error(
          "Ocorreu um erro em nossa aplicação ao consultar o servidor de CNPJ, contate a equipe técnica"
        );
      }
    }
    setLoading(false);
  }

  async function viewConsult(ecnpjConsulted) {
    setLoading(true);
    try {
      const { data: companyDataResp } = await axios.get(
        `/registers?cnpj=${ecnpjConsulted.cnpj.replace(/[^\d]/g, "")}`
      );
      let uf;
      if (companyDataResp.length === 0) {
        try {
          const { data } = await axios.get(
            `https://bfcdigital-cnpj.grupobfc.com.br/companies/${ecnpjConsulted.cnpj.replace(
              /[^\d]/g,
              ""
            )}`
          );
          uf = data.uf;
          setCompanyData({ ...data, name: data.razao_social, uf });
        } catch (err) {
          if (err.response && err.repsonse.status === 404) {
            setLoading(false);
            return toast.warn("Não foi possível verificar o estado do CNPJ");
          } else if (err.response && err.response.status === 500) {
            toast.error(
              "Ocorreu um erro em nossa consulta ao servidor de CNPJ, contate a equipe técnica"
            );
          } else {
            toast.error(
              "Ocorreu um erro em nossa aplicação ao consultar o servidor de CNPJ, contate a equipe técnica"
            );
          }
        }
      } else {
        uf = JSON.parse(companyDataResp[0].data).find((i) => i.name === "uf")
          .value;
        setCompanyData({ ...companyDataResp[0], uf });
      }
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/revenues`,
        {
          uf,
          cnpj: ecnpjConsulted.cnpj.replace(/[^\d]/g, ""),
          expiresIn:
            ecnpjConsulted.ecnpj.documents[
              ecnpjConsulted.ecnpj.documents.length - 1
            ].expires_in,
          password:
            ecnpjConsulted.ecnpj.documents[
              ecnpjConsulted.ecnpj.documents.length - 1
            ].password,
          extName:
            ecnpjConsulted.ecnpj.documents[
              ecnpjConsulted.ecnpj.documents.length - 1
            ].ext_name,
          url:
            ecnpjConsulted.ecnpj.documents[
              ecnpjConsulted.ecnpj.documents.length - 1
            ].url,
        }
      );
      if (data.length === 0) {
        toast.info(
          "Nenhuma NF-e encontrada no retorno, caso tenha acabado de enviar para análise, tente novamente em alguns minutos"
        , { autoClose: false });
      }
      setECNPJSelected(ecnpjConsulted);
      setNfes(
        data
          .filter((nfe) => nfe.status !== "rejeitado")
          .filter(
            (nfe) =>
              nfe.date === "0000-00-00" ||
              (nfe.date !== "0000-00-00" &&
                isAfter(new Date(nfe.date), subMonths(new Date(), 3)))
          )
          .sort((a, b) => a.date - b.date)
      );
      setRejects(data.filter(nfe => nfe.status === 'rejeitado'));
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err.response.data.message);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error(
          "Ocorreu um erro em nossa aplicação, contate a equipe técnica"
        );
      }
    }
    setLoading(false);
  }

  async function handleSubmit(cnpj) {
    setLoading(true);
    let ecnpjData = {};
    try {
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/customer-register/e-cnpj/file/${cnpj.replace(
          /[^\d]/g,
          ""
        )}`
      );
      ecnpjData = data;
    } catch(err) {
      if (err.response && err.response.status === 404) {
        setLoading(false);
        return toast.error('Empresa não possui e-cnpj instalado');
      } else if (err.response && err.response.status === 500) {
        setLoading(false);
        return toast.error(
          "Ocorreu um erro em nossos servidores ao buscar e-CNPJ, contate a equipe técnica"
        );
      } else {
        setLoading(false);
        return toast.error(
          "Ocorreu um erro em nossa aplicação ao buscar e-CNPJ, contate a equipe técnica"
        );
      }
    }
    setLoading(false);
    setLoading(true);
    try {
      const { data: companyDataResp } = await axios.get(
        `/registers?cnpj=${cnpj.replace(/[^\d]/g, "")}`
      );
      let uf;
      if (companyDataResp.length === 0) {
        try {
          const { data } = await axios.get(
            `https://bfcdigital-cnpj.grupobfc.com.br/companies/${cnpj.replace(
              /[^\d]/g,
              ""
            )}`
          );
          uf = data.uf;
          setCompanyData({ ...data, name: data.razao_social, uf });
        } catch (err) {
          if (err.response && err.repsonse.status === 404) {
            setLoading(false);
            return toast.warn("Não foi possível verificar o estado do CNPJ");
          } else if (err.response && err.response.status === 500) {
            toast.error(
              "Ocorreu um erro em nossa consulta ao servidor de CNPJ, contate a equipe técnica"
            );
          } else {
            toast.error(
              "Ocorreu um erro em nossa aplicação ao consultar o servidor de CNPJ, contate a equipe técnica"
            );
          }
        }
      } else {
        uf = JSON.parse(companyDataResp[0].data).find((i) => i.name === "uf")
          .value;
        setCompanyData({ ...companyDataResp[0], uf });
      }
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/revenues`,
        {
          uf,
          cnpj: cnpj.replace(/[^\d]/g, ""),
          expiresIn:
            ecnpjData.expires_in,
          password:
            ecnpjData.password,
          extName:
            ecnpjData.ext_name,
          url:
            ecnpjData.url,
        }
      );
      if (data.length === 0) {
        toast.info(
          "Nenhuma NF-e encontrada no retorno, caso tenha acabado de enviar para análise, tente novamente em alguns minutos"
        , { autoClose: false });
      }
      setNfes(
        data
          .filter((nfe) => nfe.status !== "rejeitado")
          .filter(
            (nfe) =>
              nfe.date === "0000-00-00" ||
              (nfe.date !== "0000-00-00" &&
                isAfter(new Date(nfe.date), subMonths(new Date(), 3)))
          )
          .sort((a, b) => a.date - b.date)
      );
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err.response.data.message);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error(
          "Ocorreu um erro em nossa aplicação, contate a equipe técnica"
        );
      }
    }
    setLoading(false);
  }

  async function reSendToAnalyze() {
    setLoading(true);
    try {
      await axios.post(
        `${
          process.env.REACT_APP_GATEWAY_OVH
        }/nfedistribuicaodfe/resend/cnpj/${eCNPJSelected.cnpj.replace(
          /[^\d]/g,
          ""
        )}`
      );
      toast.info("Notas não confirmadas enviadas para análise");
      setResended(true);
    } catch (err) {
      if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error(
          "Ocorreu um erro em nossa aplicação, contate a equipe técnica"
        );
      }
    }
    setLoading(false);
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Faturamento Parcial</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/revenue`}>
                Faturamento Parcial
              </Link>
            </div>
          </div>
          <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper d-flex align-items-center">
              {nfes.length > 0 || rejects.length > 0 ? (
                <>
                  <Button
                    type="button"
                    color="secondary"
                    variant="contained"
                    startIcon={<Search />}
                    onClick={() => {
                      setNfes([]);
                      setRejects([]);
                      setResended(false);
                      setRemake(false);
                      setECNPJSelected({});
                      setCompanyData({});
                    }}
                    disabled={loading}
                  >
                    Nova busca
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    className="ml-2"
                    startIcon={<DateRange />}
                    onClick={() => {
                      setPositionPerMonth(true);
                    }}
                    disabled={loading}
                  >
                    Posicionamento por Mês
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    className="ml-2 bg-danger"
                    startIcon={<MoneyOff />}
                    onClick={() => {
                      setShowRejects(true);
                    }}
                    disabled={loading}
                  >
                    NFe's Canceladas
                  </Button>
                  <Button type="button" color="primary" variant="contained" className="ml-2 bg-dark" startIcon={<PictureAsPdf />} onClick={() => exportToPdf({ company: companyData, total, nfes, })}>
                    Exportar em PDF
                  </Button>
                </>
              ) : (
                <Dropdown alignRight>
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    Ações
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as="button" onClick={() => setOpenSearch(true)}>Buscar</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => setOpenNew(true)}>Novo Faturamento</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalAdd open={openNew} setOpen={() => setOpenNew(false)} getConsult={(value) => handleSubmit(value)}/>
      <ModalSearch open={openSearch} setOpen={() => setOpenSearch(false)} getConsult={(c) => viewConsult({
        cnpj: c.cnpj,
        ecnpj: {
          documents: [{
            ...c,
            ext_name: c.extname,
            url: 'a',
            expires_in: c.expiresIn,
          }],
        }
      })}/>
      <ModalMonths
        open={positionPerMonth}
        setOpen={(e) => setPositionPerMonth(e)}
        nfes={nfes}
      />
      <ModalRejects 
        open={showRejects}
        setOpen={(e) => setShowRejects(e)}
        nfes={rejects}
      />
      {nfes.length === 0 && rejects.length === 0 && (
        <Paper
          style={{ padding: "40px", width: "100%" }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          {loading && <LinearProgress />}
          <div style={{ width: "100%" }}>
            <Table responsive="lg" hover>
              <thead>
                <th>Razão Social</th>
                <th>CNPJ</th>
                <th>Data de Consulta</th>
                <th></th>
              </thead>
              <tbody>
                {lastConsulted.map((c) => (
                  <tr key={c._id}>
                    <td>{c.company}</td>
                    <td>{normalizeCnpj(c.cnpj)}</td>
                    <td>
                      {format(new Date(c.updatedAt), "dd/MM/yyyy - HH:mm")}
                    </td>
                    <td className="text-right">
                      <button
                        type="button"
                        disabled={loading}
                        style={{
                          padding: "0px",
                          margin: "0px",
                          background: "none",
                          border: "none",
                        }}
                        onClick={() => {
                          viewConsult({
                            cnpj: c.cnpj,
                            ecnpj: {
                              documents: [{
                                ...c,
                                ext_name: c.extname,
                                url: 'a',
                                expires_in: c.expiresIn,
                              }],
                            }
                          });
                        }}
                      >
                        <Visibility />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <hr />
            <div className="d-flex justify-content-center">
            <Pagination>
              <Pagination.Prev as="button" onClick={() => setPage(page - 1)} disabled={page === 1 || loading}/>
              <Pagination.Item active>{page}</Pagination.Item>
              <Pagination.Next as="button" onClick={() => setPage(page + 1)} disabled={lastConsulted.length < perPage || loading}/>
            </Pagination>
            </div>
          </div>
        </Paper>
      )}
      {(nfes.length > 0 || rejects.length > 0) && (
        <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 3fr",
              gridGap: "4px",
            }}
          >
            <Paper className="d-flex flex-column shadow">
              <h3
                className="text-center d-flex justify-content-center align-items-center mb-0"
                style={{
                  flex: "1.50",
                  background: "#f9f9f9",
                  borderRadius: "4px 4px 0px 0px",
                }}
              >
                {companyData.name}
              </h3>
              <h5
                className="text-center d-flex justify-content-center align-items-center mb-0 shadow"
                style={{
                  flex: "0.50",
                  background: "#eee",
                  borderRadius: "0px 0px 4px 4px",
                }}
              >
                {normalizeCnpj(companyData.cnpj)}
              </h5>
            </Paper>
            <Paper className="ml-4 shadow" style={{ padding: "25px" }}>
              <div className="d-flex align-items-center">
                <MonetizationOn className="mr-4" style={{ fontSize: "80px" }} />
                <div>
                  <h5>Faturamento parcial de:</h5>
                  <h2>R$ {normalizeCurrency(total)}</h2>
                  <h5>
                    {nfes[0] ? (
                      <>
                      entre{" "}
                    {format(subMonths(new Date(), 3), "dd/MM", {
                      locale: pt_BR,
                    })}{" "}
                    - {format(new Date(nfes[0].date && nfes[0].date !== '0000-00-00' ? new Date(nfes[0].date) : new Date()), "dd/MM", { locale: pt_BR })}
                      </>
                    ) : (
                      <>
                        Nenhuma nota confirmada ou a confirmar
                      </>
                    )}
                    
                  </h5>
                </div>
                <div className="ml-auto mb-auto d-flex flex-column">
                  {nfes.filter((nfe) => nfe.status === "nao confirmado")
                    .length > 0 && (
                    <button
                      type="button"
                      className="btn btn-outline-warning btn-sm"
                      onClick={reSendToAnalyze}
                      disabled={loading || reSended}
                    >
                      {loading && (
                        <CircularProgress
                          color="primary"
                          size="16px"
                          className="mr-2"
                        />
                      )}{" "}
                      Reenviar NFE's Não confirmadas
                    </button>
                  )}
                  {nfes.filter((nfe) => nfe.status === "em analise").length ===
                    0 &&
                    !remake && (
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm mt-2"
                        onClick={remakeDist}
                        disabled={loading}
                      >
                        {loading ? (
                          <CircularProgress
                            color="primary"
                            size="16px"
                            className="mr-2"
                          />
                        ) : (
                          <Sync />
                        )}{" "}
                        Buscar novas NFE's
                      </button>
                    )}
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="text-primary">Total de NFE's: {nfes.length}</h5>
                {nfes.filter((nfe) => nfe.status === "nao confirmado").length >
                  0 && (
                  <h5 className="text-warning">
                    Não confirmadas:{" "}
                    {
                      nfes.filter((nfe) => nfe.status === "nao confirmado")
                        .length
                    }
                  </h5>
                )}
              </div>
              <ProgressBar>
                <ProgressBar
                  variant="primary"
                  now={
                    (nfes.filter(
                      (nfe) =>
                        nfe.status !== "nao confirmado" &&
                        nfe.status !== "em analise"
                    ).length /
                      nfes.length) *
                    100
                  }
                  key={1}
                />
                <ProgressBar
                  variant="dark"
                  now={
                    (nfes.filter((nfe) => nfe.status === "em analise").length /
                      nfes.length) *
                    100
                  }
                  animated
                  label={
                    nfes.filter((nfe) => nfe.status === "em analise").length > 0
                      ? `${
                          nfes.filter((nfe) => nfe.status === "em analise")
                            .length
                        } Em análise`
                      : ""
                  }
                  key={1}
                />
                <ProgressBar
                  variant="warning"
                  striped
                  now={
                    (nfes.filter((nfe) => nfe.status === "nao confirmado")
                      .length /
                      nfes.length) *
                    100
                  }
                  key={2}
                />
              </ProgressBar>
            </Paper>
          </div>
          <Paper style={{ padding: "25px" }} className="mt-4">
            <Table responsive="lg" hover>
              <thead className="bg-light">
                <tr>
                  <th>Sacado</th>
                  <th>Chave NF-e</th>
                  <th>Valor</th>
                  <th>Data de expedição</th>
                  <th className="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {nfes.map((nfe) => (
                  <tr key={nfe.id}>
                    <td>{(nfe.sacado && nfe.sacadoCnpj) ? (<>{nfe.sacado}<br/>{normalizeCnpj(nfe.sacadoCnpj)}</>) : '-'}</td>
                    <td>{nfe.chNfe}</td>
                    <td>R$ {normalizeCurrency(nfe.value)}</td>
                    <td>
                      {nfe.date !== "0000-00-00" &&
                        format(new Date(nfe.date), "dd/MM/yyyy")}
                    </td>
                    <td
                      className={`text-center ${nfe.status === "confirmado" &&
                        " bg-success"}
                        ${nfe.status === "nao confirmado" && "bg-warning"}
                        ${nfe.status === "a confirmar" &&
                          "bg-primary text-white"}`}
                    >
                      {nfe.status.toUpperCase()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Paper>
        </>
      )}
    </div>
  );
}

export default RevenuePage;
