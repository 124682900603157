import React, { memo, useState } from 'react';
import iconDocument from "../utils/iconDocument";
import axios from "axios";
import { toast } from "react-toastify";
import { addHours, format, subHours } from "date-fns";
import { Modal, Backdrop, makeStyles, CircularProgress } from "@material-ui/core";
import { HighlightOff, Delete, Publish, Input, Folder, Close, ArrowBack } from "@material-ui/icons";
import { Col, Row } from "react-bootstrap";
import ModalTrashRepositorie from "./Modais/ModalTrashRepositorie";
import verifyProfiles from "../../utils/verifyProfiles";

function Repositorie({ documentsRepository, document_types, reloadDocument, register_id, editable, user }) {
  const [updateDocName, setUpdateDocName] = useState({ index: null, value: '' });
  const [open, setOpen] = useState(false);
  const [docs, setDocs] = useState([]);
  const [move, setMove] = useState({ id: null, name: null, open: false });
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ id: null, name: null });

  const useStyles = makeStyles(theme => ({
    modalsm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fadesm: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      borderRadius: "5px",
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "600px",
      height: "500px",
      overflow: "auto"
    },
  }));
  const classes = useStyles();

  function dateByTimezone(date) {
    const timezone = new Date().getTimezoneOffset() / 60;
    if(timezone >= 0){
      return subHours(new Date(date), timezone)
    }
    return addHours(
      new Date(date),
      Math.abs(timezone)
    )
  }

  async function handleUpdateDocName(item) {
    if (updateDocName.value !== '') {
      try {
        await axios.put(`/documents/${item.id}`, {
          name: updateDocName.value,
        });
        setUpdateDocName({ index: null, value: '' });
      } catch (err) {
        toast.error(err.message);
      }
    }
    reloadDocument();
  }

  async function handleMove(to, name) {
    setLoading(true);
    try {
      await axios.put(`/document_repositories/update/${move.id}`, {
        document_type: to,
      })
      toast.info('Arquivo movido');
      reloadDocument()
      setMove({ id: null, name: null, open: false });
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false);
  }

  async function handleDelete(id) {
    setLoading(true);
    try {
      await axios.put(`document_repositories/destroy/${id}`);
      toast.success('Documento Deletado');
      reloadDocument();
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false);
  }

  async function handleUpload() {
    setLoading(true);
    try {
      const data = new FormData();
      for (let i = 0; i < docs.length; i++) {
        data.append('files[]', docs[i]);
      }
      await axios.post(`document_repositories/upload/${register_id}`, data, {
        headers: {
          "Content-Type": 'multipart/form-data',
        }
      })
      setDocs([]);
      reloadDocument();
      toast.success('Documentos Enviados');
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false)
  }

  return (
    <>
      <Modal
        className={classes.modalsm}
        open={move.open}
        onClose={() => setMove({ id: null, name: null, open: false })}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className={classes.fadesm}>
          <Row>
            <Col lg={11}>
              <h4>Mover Documento: {move.name}</h4>
            </Col>
            <Col lg={1}>
              <button
                type="button"
                onClick={() => setMove({ id: null, name: null, open: false })}
                style={{ background: 'none', border: 'none' }}
              >
                <Close />
              </button>
            </Col>
          </Row>
          <table className="table table-bordered">
            <thead>
              <tr>
                <td>Pasta</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Repositório/</td>
                <td>
                  <button
                    type="button"
                    className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill kt-badge--rounded"
                    onClick={() => handleMove(null, null)}
                    disabled={loading}
                  >
                    Mover
                    </button>
                </td>
              </tr>
              {document_types && document_types.map(doc => (
                <tr key={doc.document_type.id}>
                  <td>{doc.document_type.name}</td>
                  <td>
                    <button
                      type="button"
                      className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill kt-badge--rounded"
                      onClick={() => handleMove(doc.document_type.id, doc.document_type.name)}
                      disabled={loading}
                    >
                      Mover
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingTop: '10px',
      }}>
        <h3>Repositório/
          <small style={{ fontSize: '13px', fontWeight: 'bolder' }}>
            {filter.name !== null && filter.name}
          </small>
        </h3>
        <div>
          {docs.length > 0 ? (
            <>
              <button
                type="button"
                className="btn btn-info"
                onClick={handleUpload}
                disabled={loading}
              >
                {loading ? <CircularProgress style={{ color: '#fff' }} size={16} /> : (
                  <>
                    <Publish /> Enviar {docs.length} Documento(s)
                </>
                )}
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => setDocs([])}
                style={{ margin: '0 5px' }}
                disabled={loading}
              >
                Cancelar
            </button>
            </>
          ) : editable && (verifyProfiles(user, "analist") ||
          verifyProfiles(user, "manager_register_confirmation")) && (
            <>
              <label htmlFor="upload_files" className="btn btn-success">
                <Publish /> Selecionar Documentos
                </label>
              <input type="file" onChange={event => setDocs(event.target.files)} accept="image/jpeg, application/pdf" id="upload_files" style={{ display: 'none' }} multiple="multiple" />
            </>
          )
          }
          {
            (verifyProfiles(user, "analist") ||
            verifyProfiles(user, "manager_register_confirmation"))  && (
              <button type="button" onClick={() => setOpen(true)} className="trash_button">
              <Delete /> Lixeira
            </button>
            )
          }
        
        </div>
      </div>
      <hr />
      <Row>
        <ModalTrashRepositorie
          open={open}
          documentsRepository={documentsRepository.filter(item => item.status === 0)}
          onClose={() => setOpen(false)}
          onReload={() => reloadDocument()}
          editable={editable}
        />
        <Col lg={12} className="box__document_repo mt-4" style={{ alignItems: 'flex-start' }}>
          {filter.id !== null && (
            <button
              className="document_repo"
              style={{ position: 'relative', paddingTop: '20px' }}
              onClick={() => setFilter({
                id: null,
                name: null
              })}
            >
              <ArrowBack style={{ fontSize: '50px', color: '#444' }} />
            Voltar
            </button>
          )}
          {filter.id === null ?
            documentsRepository.sort((a, b) => {
              if(a.name < b.name) { return -1; }
              if(a.name > b.name) { return 1; }
              return 0;
              }) && documentsRepository.map((e, i) => {
              if (e.status === 1 && e.document_type === null) {
                return (
                  <div className="document_repo" key={i} style={{ position: 'relative', paddingTop: '20px' }}>
                    {editable &&  (verifyProfiles(user, "analist") ||
           verifyProfiles(user, "manager_register_confirmation")) && (
                      <>
                        <button
                          type="button"
                          style={{ position: 'absolute', top: 0, right: '10px' }}
                          className="delete_document"
                          disabled={loading}
                          onClick={() => handleDelete(e.id)}
                        >
                          <HighlightOff style={{ fontSize: `18px` }} />
                        </button>
                        <button
                          type="button"
                          style={{ position: 'absolute', top: '24px', right: '10px' }}
                          className="move_document"
                          disabled={loading}
                          onClick={() => setMove({ id: e.id, name: e.name, open: true })}
                        >
                          <Input style={{ fontSize: `18px` }} />
                        </button>
                      </>
                    )}
                    <a href={e.url} rel="noopener noreferrer" target="_blank">
                      {iconDocument(e.url)}
                    </a>
                    {updateDocName.index === i ?
                      <input type="text"
                        value={updateDocName.value}
                        onChange={ev => setUpdateDocName({ ...updateDocName, value: ev.target.value })}
                        onBlur={() => handleUpdateDocName(e)} />
                      : <button
                        type="button"
                        onClick={() => setUpdateDocName({ index: i, value: e.name })}
                        disabled={!editable}
                      >
                        {e.name}
                      </button>}
                    {format(
                      dateByTimezone(e.created_at), 'dd/MM/yyyy HH:mm'
                    )}
                  </div>
                )
              } else {
                return '';
              }
            }) : documentsRepository.map((e, i) => {
              if (e.status === 1 && e.document_type === filter.id) {
                return (
                  <div className="document_repo" key={i} style={{ position: 'relative', paddingTop: '20px' }}>
                    {editable && (verifyProfiles(user, "analist") ||
           verifyProfiles(user, "manager_register_confirmation")) && (
                      <>
                        <button
                          type="button"
                          style={{ position: 'absolute', top: 0, right: '10px' }}
                          className="delete_document"
                          disabled={loading}
                          onClick={() => handleDelete(e.id)}
                        >
                          <HighlightOff style={{ fontSize: `18px` }} />
                        </button>
                        <button
                          type="button"
                          style={{ position: 'absolute', top: '24px', right: '10px' }}
                          className="move_document"
                          disabled={loading}
                          onClick={() => setMove({ id: e.id, name: e.name, open: true })}
                        >
                          <Input style={{ fontSize: `18px` }} />
                        </button>
                      </>
                    )}
                    <a href={e.url} rel="noopener noreferrer" target="_blank">
                      {iconDocument(e.url)}
                    </a>
                    {updateDocName.index === i ?
                      <input type="text"
                        value={updateDocName.value}
                        onChange={ev => setUpdateDocName({ ...updateDocName, value: ev.target.value })}
                        onBlur={() => handleUpdateDocName(e)} />
                      : <button
                        type="button"
                        onClick={() => setUpdateDocName({ index: i, value: e.name })}
                        disabled={!editable}
                      >
                        {e.name}
                      </button>}
                    {format(
                      dateByTimezone(e.created_at), 'dd/MM/yyy HH:mm'
                    )}
                  </div>
                )
              } else {
                return '';
              }
            })}
          {filter.id === null && document_types && document_types.map(document => (
            <button
              className="document_repo"
              key={document.id}
              style={{ position: 'relative', paddingTop: '20px' }}
              onClick={() => setFilter({
                id: document.document_type.id,
                name: document.document_type.name
              })}
            >
              <span className="counter_items_folder">
                {documentsRepository && 
                documentsRepository.filter(doc => 
                doc.document_type === document.document_type.id && 
                doc.status === 1).length}
              </span>
              <Folder style={{ fontSize: '50px', color: '#fcba03' }} />
              {document.document_type.name}
              <small style={{ fontWeight: 'bold'}}>{document.document_type.type === "company" ? "Pessoa Jurídica" : "Pessoa Física"}</small>
            </button>
          ))}
        </Col>
      </Row>
    </>
  );
}

export default memo(Repositorie);
