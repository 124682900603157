import React, { memo, useEffect, useMemo, useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { normalizeCurrency } from "../utils/normalize";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Table } from 'react-bootstrap';
import axios from "axios";
import { toast } from "react-toastify";

function ProtestItem({ protest }) {
  const getAllValue = useMemo(() => {
    let count = 0;
    protest.json.cartorio &&
      protest.json.cartorio.forEach((c) => {
        c.dadosCartorio &&
          c.dadosCartorio.forEach((dC) => {
            if(dC.titulos) {
              if(Array.isArray(dC.titulos)) {
                dC.titulos &&
                dC.titulos.forEach((t) => {
                  if (t.valorProtestado) {
                    count += Number(t.valorProtestado.replace(/[^\d]/g, ""));
                  }
                });
              }else{
                Object.keys(dC.titulos).forEach(cnpj => {
                  dC.titulos[cnpj].forEach((t) => {
                    if (t.valorProtestado) {
                      count += Number(t.valorProtestado.replace(/[^\d]/g, ""));
                    }
                  })
                })
              }
            }
          });
      });
      if(count === 0) {
        return '0,00'
      }
    return normalizeCurrency(count);
  }, [protest]);

  const numChaves = useMemo(() => {
    if(Object.keys(protest).length === 0) {
      return []
    }
    let arr = []
    protest && protest.json && protest.json.cartorio && protest.json.cartorio.forEach(cartorio => {
      cartorio.dadosCartorio.forEach(dadosCartorio => {
        if(Array.isArray(dadosCartorio.titulos)) {
          dadosCartorio.titulos.forEach(title => {
            arr.push(title.nm_chave)
          })
        }else{
          Object.keys(dadosCartorio.titulos).forEach(cnpj => 
            dadosCartorio.titulos[cnpj]
            .forEach((title) => {
              arr.push(title.nm_chave)
            }))
        }
      })
    })

    return arr
  }, [protest])

  const [availableKeys, setAvailableKeys] = useState([])

  useEffect(() => {
    if(numChaves.length === 0) {
      setAvailableKeys([])
    }else{
      axios.get(
        `${process.env.REACT_APP_GATEWAY_OVH}/protests/inst-protest/avalidable/by-keys`, {
          params: {
            nm_chaves: numChaves.join(',')
          }
        }
      ).then(res => setAvailableKeys(res.data))
    }
  }, [numChaves])

  const TitleItem = ({ titulo, instrumentLink, onUpdateKey }) => {
    const [loadingInstrument, setLoadingInstrument] = useState(false)
    return (
      <tr>
        <td>{titulo.dataProtesto || "[SEM INFORMAÇÃO]"}</td>
        <td>{titulo.dataVencimento || "[SEM INFORMAÇÃO]"}</td>
        <td>R$ {titulo.valorProtestado || "[SEM INFORMAÇÃO]"}</td>
        <td>{titulo.nomeApresentante || "[SEM INFORMAÇÃO]"}</td>
        <td>{titulo.nomeCedente || "[SEM INFORMAÇÃO]"}</td>
        <td className="text-right">{instrumentLink ? <a href={instrumentLink} target="_blank"><strong>INSTRUMENTO</strong></a> : (
          <>
            {titulo.nm_chave ? (
              <button type="button" onClick={() => {
                setLoadingInstrument(true)
                axios.post(
                  `${process.env.REACT_APP_GATEWAY_OVH}/protests/inst-protest/${titulo.nm_chave}`, 
                ).then(res => onUpdateKey({
                  _id: res.data._id,
                  nm_chave: res.data.nm_chave
                }))
                .catch(err => {
                  if(err.response && err.response.data) {
                    toast.error(err.response.data.message)
                  }
                })
                .finally(() => setLoadingInstrument(false))
              }} disabled={loadingInstrument}>
                {loadingInstrument ? <span>Consulando</span> : <strong>
                  CONSULTAR INSTRUMENTO
                </strong>}
              </button>
            ) : null}
          </>
        )}</td>
      </tr>
    )
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "100%" }}
        >
          <div>
            <h5>{protest.amountTitles} Título(s)</h5>
            <h6 className="mb-0">R$ {getAllValue}</h6>
          </div>
          <div>
            {protest.dates.map(d => <h6 key={d}>
              <i className="far fa-calendar-alt mr-2"></i>
              {d}
            </h6>)}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
      {protest.json.qtdTitulos > 0 &&
            protest.json.cartorio.length > 0 &&
            protest.json.cartorio.map((cartorio, cIndex) => {
              if (
                cartorio.dadosCartorio.find((c) =>
                  Array.isArray(c.titulos) ? c.titulos.find(
                    (t) => typeof t.valorProtestado !== "undefined"
                  ) : Object.keys(c.titulos).find(cnpj => {
                    return c.titulos[cnpj].find(
                      (t) => typeof t.valorProtestado !== "undefined"
                    )
                  })
                )
              ) {
                return (
                  <div
                    key={cIndex}
                    style={{
                      width: "100%",
                      padding: "15px",
                      background: "#f9f9f9",
                      margin: "20px 0px",
                      border: "1px solid #c9c9c9",
                    }}
                  >
                    <div
                      className="d-flex justify-content-between align-items-center"
                      key={cIndex}
                      style={{ width: "100%" }}
                    >
                      <h5>UF:</h5>
                      <h5>{cartorio.uf}</h5>
                    </div>
                    <hr />
                    {cartorio.dadosCartorio.length > 0 &&
                      cartorio.dadosCartorio.map((dadosCartorio, dIndex) => {
                        if (
                          Array.isArray(dadosCartorio.titulos) ? dadosCartorio.titulos.find(
                            (titulo) =>
                              typeof titulo.valorProtestado !== "undefined"
                           ): Object.keys(dadosCartorio.titulos).find(cnpj => dadosCartorio.titulos[cnpj].find(
                            (titulo) =>
                              typeof titulo.valorProtestado !== "undefined"
                           ))
                        ) {
                          return (
                            <div key={dIndex} style={{ width: "100%" }}>
                              <Table responsive="lg" style={{ width: "100%" }}>
                                <thead style={{ background: "#f0f0f0" }}>
                                  <tr>
                                    <th>{dadosCartorio.nomeCartorio}</th>
                                    <th>{dadosCartorio.telefone}</th>
                                    <th>
                                      {dadosCartorio.qtdTitulos} Título(s)
                                    </th>
                                  </tr>
                                </thead>
                              </Table>
                              <Table
                                responsive="lg"
                                hover
                                style={{
                                  marginTop: "-15px",
                                  marginBottom: "30px",
                                  width: "100%",
                                }}
                              >
                                <thead className="bg-light">
                                  <tr>
                                    <th>Data Protesto</th>
                                    <th>Data Vencimento</th>
                                    <th>Valor Protestado</th>
                                    <th>Apresentante</th>
                                    <th>Protestante</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(dadosCartorio.titulos) ? (
                                    <>
                                    {dadosCartorio.titulos.length > 0 &&
                                    dadosCartorio.titulos
                                      .filter(
                                        (titulo) =>
                                          typeof titulo.valorProtestado !==
                                          "undefined"
                                      )
                                      .map((titulo, tIndex) => {
                                        const keyId = availableKeys.find(i => String(i.nm_chave) === String(titulo.nm_chave))
                                        return (
                                          <TitleItem key={tIndex} titulo={titulo} onUpdateKey={(d) => setAvailableKeys([...availableKeys, d])} instrumentLink={keyId ? `${process.env.REACT_APP_GATEWAY_OVH}/protests/inst-protest/${keyId._id}` : undefined}/>
                                        )
                                      })}
                                    </>
                                  ) : (
                                    <>
                                    {Object.keys(dadosCartorio.titulos).map(cnpj => dadosCartorio.titulos[cnpj].filter(
                                        (titulo) =>
                                          typeof titulo.valorProtestado !==
                                          "undefined"
                                      )
                                      .map((titulo, tIndex) => {
                                        const keyId = availableKeys.find(i => String(i.nm_chave) === String(titulo.nm_chave))
                                        return (
                                          <TitleItem key={tIndex} titulo={titulo} onUpdateKey={(d) => setAvailableKeys([...availableKeys, d])} instrumentLink={keyId ? `${process.env.REACT_APP_GATEWAY_OVH}/protests/inst-protest/${keyId._id}` : undefined}/>
                                        )
                                      }))}
                                    </>
                                  )}
                                  
                                </tbody>
                              </Table>
                            </div>
                          );
                        }
                        return "";
                      })}
                  </div>
                );
              }
              return "";
            })}
            </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default memo(ProtestItem);
