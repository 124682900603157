import React, { useEffect, memo, useState } from "react";

import { format } from "date-fns";
import { toast } from "react-toastify";
import verifyTitleEvent from "../utils/verifyTitleEvent";

import axios from "axios";
// import { normalizeCnpj } from "../utils/normalize";

import printChargeEmail from "../utils/printChargeEmail";
import printChargeReportEmail from "../utils/printChargeReportEmail";

function ItemDetail({ e, index, onResend }) {
  const [titles, setTitles] = useState([]);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const obj = JSON.parse(e.json);
    setTitles(obj.titles);
  }, [e.json]);
  /*
  const [edit, setEdit] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [error, setError] = useState("");

  async function handleChangeEmail() {
    setError("");
    if(newEmail.length === 0) {
      return setError('Preencha esse campo');
    }
    if(newEmail.findIndex('@') === -1) {
      return setError('Preencha um email válido');
    }
    let splited = newEmail.split('@');
    if(splited[1].findIndex('.') === -1) {
      return setError('Preencha um email válido');
    }
    try {
      await axios.put(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/charge-list/email/${e._id}`, {
        email: newEmail,
      });
      e.email = newEmail;
      setEdit(false);
      setNewEmail("");
    }catch(err) {
      if(err.response && err.response.status === 404) {
        toast.info('Sacado não encontrado')
      }else{
        toast.error('Ocorreu um erro ao tentar atualizar email, tente novamente')
      }
    }
  }
  */
  async function handlePrintDetail() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/charge-list/sacado-titles/${e._id}`
      );
      printChargeEmail({
        createdAt: format(
          new Date(response.data.sacado.createdAt),
          "dd/MM/yyyy 'às' HH:mm"
        ),
        sacado: response.data.sacado,
        events: response.data.titles
      });
    } catch (err) {
      toast.error("Ocorreu um erro, tente novamente mais tarde");
    }
  }

  function isSituation(events) {
    if (events && events.length > 0) {
      if (
        events[events.length - 1].event === "click" ||
        events[events.length - 1].event === "open"
      ) {
        return "Lido";
      } else if (
        events[events.length - 1].event === "processed" ||
        events[events.length - 1].event === "delivered"
      ) {
        return "Enviado";
      } else if (
        events[events.length - 1].event === "dropped" ||
        events[events.length - 1].event === "deferred" ||
        events[events.length - 1].event === "bounce"
      ) {
        return "Inválido";
      }
    } else if (e.email === "") {
      return "Não possui email";
    } else {
      return "Não enviado";
    }
  }

  async function handlePrintReport(situation) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/charge-list/sacado-titles/${e._id}`
      );
      let weekDay = format(new Date(response.data.sacado.createdAt), "i");

      if (weekDay === "1") {
        weekDay = "segunda-feira";
      } else if (weekDay === "2") {
        weekDay = "terça-feira";
      } else if (weekDay === "3") {
        weekDay = "quarta-feira";
      } else if (weekDay === "4") {
        weekDay = "quinta-feira";
      } else if (weekDay === "5") {
        weekDay = "sexta-feira";
      } else if (weekDay === "6") {
        weekDay = "sábado";
      } else if (weekDay === "7") {
        weekDay = "domingo";
      }

      const monthNumber = format(new Date(response.data.sacado.createdAt), "M");

      let month = "";
      if (monthNumber === "1") {
        month = "janeiro";
      } else if (monthNumber === "2") {
        month = "fevereiro";
      } else if (monthNumber === "3") {
        month = "março";
      } else if (monthNumber === "4") {
        month = "abril";
      } else if (monthNumber === "5") {
        month = "maio";
      } else if (monthNumber === "6") {
        month = "junho";
      } else if (monthNumber === "7") {
        month = "julho";
      } else if (monthNumber === "8") {
        month = "agosto";
      } else if (monthNumber === "9") {
        month = "setembro";
      } else if (monthNumber === "10") {
        month = "outubro";
      } else if (monthNumber === "11") {
        month = "novembro";
      } else if (monthNumber === "12") {
        month = "dezembro";
      }

      const createdAtComplete = `${weekDay}, ${format(
        new Date(response.data.sacado.createdAt),
        "dd"
      )} de ${month} de ${format(
        new Date(response.data.sacado.createdAt),
        "yyyy  HH:mm"
      )}`;

      printChargeReportEmail({
        createdAtComplete: createdAtComplete,
        createdAt: format(
          new Date(response.data.sacado.createdAt),
          "dd/MM/yyyy 'às' HH:mm"
        ),
        sacado: response.data.sacado,
        events: response.data.titles,
        situation: situation
      });
    } catch (err) {
      toast.error("Ocorreu um erro, tente novamente mais tarde");
    }
  }

  return (
    <div
      key={index}
      style={{
        padding: "15px",
        border: "1px solid #eee",
        margin: "20px 0px",
        background: "#f9f9f9",
        position: "relative"
      }}
    >
      <div className="d-flex justify-content-start align-items-start">
        <i
          className="fas fa-building mr-4"
          style={{
            padding: "20px 20px",
            fontSize: "40px",
            background: "#eee",
            borderRadius: "6px"
          }}
        ></i>
        <div style={{ textAlign: "left" }}>
          <h4>{e.name}</h4>
          <p className="mb-1">
            <i className="fas fa-id-card mr-2"></i>
            {e.saca_id}
          </p>
          <p className="mb-1">
            <i className="fas fa-envelope mr-2"></i>
            {e.email || "-"}
          </p>
        </div>
        <div
          className="d-flex"
          style={{ position: "absolute", top: "10px", right: "10px" }}
        >
          {e.events && e.events.length > 0 ? (
            <>
              {(e.events[e.events.length - 1].event === "click" ||
                e.events[e.events.length - 1].event === "open") && (
                <span
                  style={{ padding: "10px" }}
                  className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded"
                >
                  Lido
                </span>
              )}
              {(e.events[e.events.length - 1].event === "processed" ||
                e.events[e.events.length - 1].event === "delivered") && (
                <span
                  style={{ padding: "10px" }}
                  className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill kt-badge--rounded"
                >
                  Enviado
                </span>
              )}
              {(e.events[e.events.length - 1].event === "dropped" ||
                e.events[e.events.length - 1].event === "deferred" ||
                e.events[e.events.length - 1].event === "bounce") && (
                <span
                  style={{ padding: "10px" }}
                  className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded"
                >
                  Inválido
                </span>
              )}
            </>
          ) : e.email === "" ? (
            <span
              style={{ padding: "10px" }}
              className="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill kt-badge--rounded"
            >
              Não possui email
            </span>
          ) : (
            <span
              style={{ padding: "10px" }}
              className="kt-badge kt-badge--secondary kt-badge--inline kt-badge--pill kt-badge--rounded"
            >
              Enviando
            </span>
          )}
        </div>
      </div>
      <div
        className="d-flex justify-content-end align-items-center"
        style={{ width: "100%" }}
      >
        <button
          className="btn btn-sm btn-upper btn-info"
          onClick={() => handlePrintDetail()}
        >
          Imprimir PDF
        </button>
        {(isSituation(e.events) !== "Inválido" &&
          isSituation(e.events) !== "Não possui email" &&
          isSituation(e.events) !== "Não enviado") &&
          <button
            className="btn btn-sm btn-upper btn-info ml-4"
            onClick={() => handlePrintReport(isSituation(e.events))}
          >
            Imprimir relatório
          </button>
        }

        <button
          className="btn btn-sm ml-4"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (
            <>
              Ver menos <i className="fas fa-angle-up ml-2"></i>
            </>
          ) : (
            <>
              Ver mais <i className="fas fa-angle-down ml-2"></i>
            </>
          )}
        </button>
      </div>
      <ul
        style={{
          listStyle: "none",
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          marginTop: "20px",
          padding: "0",
          justifyContent: "flex-end"
        }}
      >
        {titles.map((e, i) => {
          return (
            <li
              className="mr-3"
              style={{ textAlign: "center", fontSize: "11px" }}
              key={i}
            >
              <a
                href={`${
                  process.env.REACT_APP_API_BFC_DIGITAL_URL
                }/send-mail-sendgrid/boleto/titulo_${e.titleData &&
                  e.titleData.title_id}.pdf`}
                className="d-flex flex-column"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="far fa-file-alt"
                  style={{ fontSize: "24px", marginBottom: "5px" }}
                ></i>
                {e.titleData && e.titleData.title_id}.pdf
              </a>
            </li>
          );
        })}
      </ul>
      <hr />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p>Hash: {e.sg_message_id}</p>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p>
          Data de Processamento:{" "}
          {format(new Date(e.createdAt), "dd/MM/yyyy 'às' HH:mm")}
        </p>
      </div>
      {expanded && (
        <div>
          {e.events && e.events.length > 0 ? (
            <table className="table table-bordered mt-4">
              <thead>
                <tr className="bg-secondary text-dark">
                  <td>E-mail</td>
                  <td>Evento</td>
                  <td>Data</td>
                </tr>
              </thead>
              <tbody>
                {e.events.map((event, index) => (
                  <tr className="bg-white" key={index}>
                    <td>
                      <small>{event.email}</small>
                    </td>
                    <td>
                      <small>{verifyTitleEvent(event.event)}</small>
                    </td>
                    <td>
                      <small>
                        {format(
                          new Date(event.timestamp),
                          "dd/MM/yyyy 'às' HH:mm"
                        )}
                      </small>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <span>Nenhum evento registrado...</span>
          )}
        </div>
      )}
    </div>
  );
}

export default memo(ItemDetail);
