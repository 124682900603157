import React, { memo, useState } from "react";

import { format } from "date-fns";
import { formatEvent } from "../utils/normalize";

// import { normalizeCnpj } from "../utils/normalize";

function ItemDetail({ payer, index, handleSelect, check }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <button
      key={index}
      style={{
        padding: "15px",
        border: "1px solid #eee",
        margin: "20px 0px",
        background: "#f9f9f9",
        position: "relative",
      }}
      onClick={() => handleSelect(payer)}
    >
      <div className="d-flex justify-content-start align-items-start">
        <div style={{ textAlign: "left" }}>
          <h4>{payer.name}</h4>
          <p className="mb-1">
            <i className="fas fa-id-card mr-2"></i>
            {payer.document}
          </p>
          <p className="mb-1">
            <i className="fas fa-envelope mr-2"></i>
            {payer.email || "-"}
          </p>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <span
            className="d-flex justify-content-center align-items-center"
            style={{
              background: "#eee",
              borderRadius: "4px",
              width: "20px",
              height: "20px",
              margin: "10px 10px 10px 20px",
            }}
          >
            {check && (
              <i className="fas fa-check" style={{ fontSize: "16px" }}></i>
            )}
          </span>
        </div>
      </div>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ width: "100%" }}
      >
        <div>
          {payer.sends && payer.sends.map(send => (
            <div className="d-flex align-items-center" key={send._id}>
              <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                {format(new Date(send.date_processing), "dd/MM/yyyy 'às' HH:mm")}
              </span>
              {formatEvent(send.status)}
            </div>
          ))}
        </div>
        <button
          className="btn btn-sm ml-4"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (
            <>
              Ver menos <i className="fas fa-angle-up ml-2"></i>
            </>
          ) : (
            <>
              Ver mais <i className="fas fa-angle-down ml-2"></i>
            </>
          )}
        </button>
      </div>
      {expanded && (
        <>
          <hr />
          <h6>Envios</h6>
          {payer.sends && payer.sends.map(send => (
            <div key={send._id}>
              <div className="d-flex justify-content-between">
                <p>Hash: {send.sg_message_id}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0">
                  Data de Processamento:{" "}
                  {format(new Date(send.date_processing), "dd/MM/yyyy 'às' HH:mm")}
                </p>
                {formatEvent(send.status)}
              </div>
              <table className="table table-bordered mt-2">
                <thead>
                  <tr className="bg-secondary text-dark">
                    <th>E-mail</th>
                    <th>Evento</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {send.events && send.events.map(e => (
                    <tr key={e.id}>
                      <td>{e.email}</td>
                      <td>{formatEvent(e.event)}</td>
                      <td>{e.timestamp ? format(new Date(e.timestamp), "dd/MM/yyyy 'às' HH:mm") : ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </>
      )}
    </button>
  );
}

export default memo(ItemDetail);
