import React from "react";

export default function statusRegisterCompleted(event) {
  const formatEvent = (e) => {
    switch(e) {
      case 'sent': return (
        <span className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill kt-badge--rounded">
          Enviado
        </span>
      );
      case 'processed': return (
        <span className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill kt-badge--rounded">
          Enviado
        </span>
      );
      case 'open': return (
        <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded">
          Lido
        </span>
      );
      case 'opened': return (
        <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded">
          Lido
        </span>
      );
      case 'click': return (
        <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded">
          Lido
        </span>
      );
      case 'dropped': return (
        <span className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded">
          Inválido
        </span>
      );
      case 'deferred': return (
        <span className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded">
          Inválido
        </span>
      )
      case 'bounce': return (
        <span className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded">
          Inválido
        </span>
      )
      case 'delivered': return (
        <span className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill kt-badge--rounded">
          Enviado
        </span>
      );
      default: return (
        <span className="kt-badge kt-badge--light kt-badge--inline kt-badge--pill kt-badge--rounded">
          Enviando...
        </span>
      );
    }
  }

  return formatEvent(event);
}
